import React from "react"
import RolesViewOne from "./RolesViewOne"

const RolesViewAll = (props) => {

    const roles = props.roles;

    return (
        <div>
            <div className='card' style={{ background: "transparent" }}>
                <div className='card-header'>
                    <div className='row'>
                        <div className='col-12'>
                            <span>All Roles</span>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <ul className="list-group">
                                {roles.map(role => (
                                    <RolesViewOne role={role}
                                        setLastModifiedRole={props.setLastModifiedRole}
                                        key={role.id} />
                                )
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RolesViewAll