import React, { useRef, useState } from "react";

// eslint-disable-next-line
import "swiper/css/bundle";
import "../styles/Slider.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import Convert64ToImage from "../services/Convert64ToImage";

const SwiperImageProject = ({ images }) => {

  const arrayOfImgs = images || []

  // console.log(arrayOfImgs)
  // console.log(data?.data?.projectImages[0].image)

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="w-75 mx-auto py-5">
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {
          arrayOfImgs.map((el) =>
            <SwiperSlide key={el.id} style={{ width: "100%" }}>
              <Convert64ToImage mainImage={el.image} fullWidth={true} />
            </SwiperSlide>
          )
        }
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={images.length}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >

        {
          arrayOfImgs.map((el) =>
            <SwiperSlide key={el.id}>
              <Convert64ToImage mainImage={el.image} />
            </SwiperSlide>
          )
        }

      </Swiper>
    </div>
  )
}

export default SwiperImageProject