import React from 'react'

const Loader = () => {
  return (
    <div className="loader-main">
      <div className="loading-container">
        <div className="loading"></div>
        <div id="loading-text">loading</div>
      </div>
    </div>
  )
}

export default Loader