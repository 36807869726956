import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { apiClient } from '../services/auth/ApiClient';
import { authService } from '../services/auth/AuthService';
import Resizer from "react-image-file-resizer";
import { useTranslation } from 'react-i18next'
import { notify } from '../components/Toast';

const ProfilePage = () => {
  const { t } = useTranslation();


  const [profileImg, setProfileImg] = useState({});

  const params = useParams();


  useEffect(() => {
    if (params.id) {
      apiClient
        .getProfileImage(params.id)
        .then(img => setProfileImg(img));
    }
  }, [params.id]);



  const [user, setUser] = useState([authService.currentUserValue]);
  const [previewImg, setPreviewImg] = useState(null);
  const [file, setFile] = useState(null);
  const [fileInputRef, setFileInputRef] = useState(null);

  const [property, setProperty] = useState([authService.currentUserValue?.property]);

  useEffect(() => {
    authService.currentUser.subscribe(x => setUser(x ? { ...x } : x));
  }, []);

  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'file');
    // }, 'base64');
  });


  const handleChange = async (event) => {
    const file = event.target.files[0];

    const resized = await resizeFile(file);

    console.log(file);
    setFile(resized);
    console.log(resized);

    setPreviewImg(URL.createObjectURL(resized));
  }

  const uploadFile = async () => {
    console.log(file);

    await apiClient.changeProfileImage(user.id, file);

    setPreviewImg(null);
    setFile(null);
    fileInputRef.value = '';
    authService.updateLoggedInUserInfo(user);
  }



  function handleUpdatedInfoUser(event) {
    // Prevent default behavior
    event.preventDefault();

    let updatedUser = {
      id: user.id,
      username: user.username,
      email: user.email,
      firstname: event.target.firstname.value,
      lastname: event.target.lastname.value,
      city: event.target.city.value,
      address: event.target.address.value
    };
    console.log(updatedUser);
    apiClient.updateUser(updatedUser)
      .then(updated => {
        console.log("Response from backend: " + JSON.stringify(updated));
        if (updated) {
          authService.updateLoggedInUserInfo(updated);
          notify({ info: "User updated successfully", status: "success" });
          
          // alert(`User info updated successfully`);
        } else {
          notify({ info: "Can not Update User", status: "failed" });
          console.log("Can not update: " + updated);
        }
      });
  }

  // function handleUpdatedLocation(event) {
  //   // Prevent default behavior
  //   event.preventDefault();

  //   let propertyToUpdate = {
  //     loc: event.target.loc.value,
  //     addr: event.target.addr.value,
  //     type: event.target.type.value
  //   };

  //   let user = authService.currentUserValue;

  //   let propOperation = property ?
  //     apiClient.updateProperty(user.id, propertyToUpdate) :
  //     apiClient.createProperty(user.id, propertyToUpdate);
  //   propOperation
  //     .then(response => {
  //       console.log("Response from backend: " + JSON.stringify(response));
  //       if (response) {
  //         const type = !property ? 'created' : 'updated';
  //         setProperty(response);
  //         let userWithUpdatedProperty = {
  //           ...user,
  //           property: response
  //         };
  //         authService.updateLoggedInUserInfo(userWithUpdatedProperty);
  //         alert(`Property ${type} successfully`);
  //       } else {
  //         console.log("Can not update: " + response);
  //       }
  //     });
  // }


  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [flagInfo, setFlagInfo] = useState(true)
  const [flagPassword, setFlagPassword] = useState(true)



  function handleChangePassword(event) {
    // Prevent default behavior
    event.preventDefault();

    let updatedPassword = {
      newPassword,
    };
    // console.log(apiClient.changePassword(updatedPassword));
    apiClient.changePassword(updatedPassword)
      .then(updated => {
        console.log("Response from backend: " + JSON.stringify(updated));
        if (updated) {
          // authService.updateLoggedInUserInfo(updated);
          alert(`User info updated successfully`);
        } else {
          console.log("Can not update: " + updated);
        }
      });
  }


  return (
    <div> {user &&
      <div className="container-xl px-4 py-3 pb-5 mt-4">

        <div className="row">
          <div className="col-xl-4">

            <div className="card mb-4 mb-xl-0">
              <div className="card-header">{t("profile_picture_text")}</div>
              <div className="card-body text-center">
                {profileImg &&
                  <img className="img-account-profile rounded-circle mb-2" src={`${profileImg}`} alt="" />
                }
                <div className="small font-italic text-muted mb-4">{t("profile_picture_more_5MB")}</div>

                <div className='row mt-3'>
                  <input className="form-control mx-auto mb-2" type="file" style={{ background: "#0d6efd", height: "45px", width: "80%", color: "#fff" }}
                    id="profileImgFile"
                    ref={(ref) => {
                      setFileInputRef(ref)
                    }}
                    onChange={handleChange} />
                </div>

                {/* disabled={!file}
                onClick={uploadFile} */}
                <button type="submit" className="btn btn-primary text-light list-btn mt-2" disabled={!file}
                  onClick={uploadFile}>
                  {t("submit_text")}
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-8">

            <div className="card mb-4">
              <div className="card-header">{t("account_details_text")}</div>
              <div className="card-body  card-profile-edit">

                <form className='edit-profile' onSubmit={handleUpdatedInfoUser}>
                  <div>
                    <div className="form-group mt-3">
                      <label>{t("profile_page_firstname")}</label>
                      <input
                        type="text"
                        className={`form-control mt-1 ${flagInfo ? "bg-input-readOnly" : ""}`}
                        placeholder={t('profile_page_firstname_placeholder')}
                        name="firstname"
                        defaultValue={user.firstname}
                        readOnly={flagInfo}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>{t("profile_page_lastname")}</label>
                      <input
                        type="text"
                        className={`form-control mt-1 ${flagInfo ? "bg-input-readOnly" : ""}`}
                        placeholder={t("profile_page_lastname_placeholder")}
                        name="lastname"
                        readOnly={flagInfo}
                        defaultValue={user.lastname}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>{t("profile_page_city")}</label>
                      <input
                        type="text"
                        className={`form-control mt-1 ${flagInfo ? "bg-input-readOnly" : ""}`}
                        placeholder={t("profile_page_city_placeholder")}
                        name="city"
                        readOnly={flagInfo}
                        defaultValue={user.city}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>{t("profile_page_address")}</label>
                      <input
                        type="text"
                        className={`form-control mt-1 ${flagInfo ? "bg-input-readOnly" : ""}`}
                        placeholder={t("profile_page_address_placeholder")}
                        name="address"
                        readOnly={flagInfo}
                        defaultValue={user.address}
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <button type="button" className="btn btn-primary list-btn btn-form" onClick={() => setFlagInfo(prev => !prev)}>
                        {t("profile_page_edit_btn")}
                      </button>

                      <button type="submit" className="btn btn-primary list-btn btn-form">
                        {t("profile_page_save_btn")}
                      </button>

                    </div>
                  </div>
                </form>




                <form className='edit-profile' onSubmit={handleChangePassword}>
                  <div>
                    <div className="form-group mt-3">
                      <label>{t("profile_page_old_password")}</label>
                      <input
                        type="text"
                        className={`form-control mt-1 ${flagPassword ? "bg-input-readOnly" : ""}`}
                        placeholder={t("profile_page_old_password_placeholder")}
                        name="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>{t("profile_page_new_password")}</label>
                      <input
                        type="text"
                        className={`form-control mt-1 ${flagPassword ? "bg-input-readOnly" : ""}`}
                        placeholder={t("profile_page_new_password_placeholder")}
                        name="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group mt-3">
                      <label>{t("profile_page_confirm_password")}</label>
                      <input
                        type="text"
                        className={`form-control mt-1 ${flagPassword ? "bg-input-readOnly" : ""}`}
                        placeholder={t("profile_page_confirm_password_placeholder")}
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <button type="button" className="btn btn-primary list-btn btn-form" onClick={() => setFlagPassword(prev => !prev)}>
                        {t("profile_page_edit_btn")}
                      </button>
                      <button type="submit" className="btn btn-primary list-btn btn-form">
                        {t("profile_page_save_btn")}
                      </button>
                    </div>
                  </div>
                </form>

                {/* <form>

                  <div className="mb-3">
                    <label className="small mb-1" htmlFor="inputUsername">Username (how your name will appear to other users on the site)</label>
                    <input className="form-control" id="inputUsername" type="text" placeholder="Enter your username" defaultValue="username" />
                  </div>

                  <div className="row gx-3 mb-3">

                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputFirstName">First name</label>
                      <input className="form-control" id="inputFirstName" type="text" placeholder="Enter your first name" defaultValue="Valerie" />
                    </div>

                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputLastName">Last name</label>
                      <input className="form-control" id="inputLastName" type="text" placeholder="Enter your last name" defaultValue="Luna" />
                    </div>
                  </div>

                  <div className="row gx-3 mb-3">

                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputOrgName">Organization name</label>
                      <input className="form-control" id="inputOrgName" type="text" placeholder="Enter your organization name" defaultValue="Start Bootstrap" />
                    </div>

                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputLocation">Location</label>
                      <input className="form-control" id="inputLocation" type="text" placeholder="Enter your location" defaultValue="San Francisco, CA" />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="small mb-1" htmlFor="inputEmailAddress">Email address</label>
                    <input className="form-control" id="inputEmailAddress" type="email" placeholder="Enter your email address" defaultValue="name@example.com" />
                  </div>

                  <div className="row gx-3 mb-3">

                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputPhone">Phone number</label>
                      <input className="form-control" id="inputPhone" type="tel" placeholder="Enter your phone number" defaultValue="555-123-4567" />
                    </div>

                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputBirthday">Birthday</label>
                      <input className="form-control" id="inputBirthday" type="text" name="birthday" placeholder="Enter your birthday" defaultValue="06/10/1988" />
                    </div>
                  </div>

                  <button className="btn btn-primary" type="button">Save changes</button>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    </div>
  )
}

export default ProfilePage