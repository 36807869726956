import React, { useEffect, useState } from "react"
import { apiClient } from "../../../services/auth/ApiClient";
import { notify } from "../../Toast";

const EditUserAssignSeparateAuthority = (props) => {

  const user = props.user;
  // let id = props.id
  const [authorities, setAuthorities] = useState([]);
  const [userSeparateAuthorityIds, setUserSeparateAuthorityIds] = useState([]);

  useEffect(() => {
    apiClient.getAllAuthorities()
      .then(allAuthorities => {
        const sorted = allAuthorities.sort((a1, a2) => a1.authority.localeCompare(a2.authority));
        setAuthorities(sorted);
      });
  }, []);

  useEffect(() => {
    if (user) {
      apiClient.getUserSeparateAuthorities(user?.id)
        .then(separateAuthorities => {
          const ids = separateAuthorities.map(authority => authority.id);
          setUserSeparateAuthorityIds(ids);
        });
    }
  }, [user]);

  const handleSubmit = async (event) => {
    // Prevent default behavior
    event.preventDefault();
    const updated = await apiClient.assignSeparateAuthoritiesToUser(user.id, userSeparateAuthorityIds);
    props.updateUser(updated);
    notify({info: "User info updated successfully", status: "success"});

  }

  const handleChange = (event) => {

    const checked = event.target.checked;
    const id = +event.target.value;

    let ids = userSeparateAuthorityIds.filter(i => i !== id);
    if (checked) ids.push(id)

    setUserSeparateAuthorityIds(ids);
  }

  return (
    <div>
      {user &&
        <div className='car d '>
          <div className='card-header' style={{ border: "1px solid" }}>
            <div className='row'>
              <div className='col-12'>
                <span>Assign separate authorities (outside roles) to the user <b>{user.username}</b></span>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit}>
              <div className='row'>
                {authorities.map(authority => (
                  <div className="col-sm-6 col-md-4 col-xl-3 border border-secondary d-flex align-items-center" key={authority.id}>
                    <input className="form-check-input" type="checkbox"
                      value={authority.id}
                      name={authority.id}
                      checked={userSeparateAuthorityIds?.includes(authority.id)}
                      onChange={handleChange}
                      id={`authority-check-${authority.id}`} />
                    <label className={`form-check-label ${localStorage.getItem("mode") == "dark" ? "text-light" : "main-color-dash"}`} style={{ margin: "20px 10px" }}
                      htmlFor={`authority-check-${authority.id}`}>
                      {authority.authority}
                    </label>
                  </div>
                )
                )}
              </div>
              <div className='row mt-4'>
                <button className='btn btn-primary' type='submit'>Assign authorities</button>
              </div>
            </form>
          </div>
        </div>
      }
    </div>
  )
}

export default EditUserAssignSeparateAuthority;