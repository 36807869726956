import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

function Completion(props) {

  const location = useLocation();

  useEffect(() => {
    // clear localStorage when URL changes
    localStorage.removeItem("donateValue");
  }, [location.pathname]);


  return <>
    {/* <h1>Thank you! </h1> */}
    <div className="donation-confirmation">
      <h2>Thank you for your donation!🎉</h2>
      <p>Your contribution {localStorage.getItem("donateValue") ? <>of <span style={{ fontWeight: '600' }}>{localStorage.getItem("donateValue")} eur</span> </> : ""} has been received and will be put to good use.</p>
    </div>
  </>;
}

export default Completion;