import React from 'react'
import EventBig from "../../assets/img/event/event-big.png"
import Date1 from "../../assets/img/icon/date.png"
import Map1 from "../../assets/img/icon/map.png"
import Clock1 from "../../assets/img/icon/clock.png"

const UpcomingEvents = () => {
  return (
    <section id="upcoming_events" className="section_padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="section_heading">
              <h3>Upcoming events</h3>
              <h2>Join our upcoming <span className="color_big_heading">events</span> for contribution</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="event_left_side_wrapper">
              <div className="event_big_img">
                <a href="event-detail.html">
                  <img src={EventBig} alt="img" />
                </a>
              </div>
              <div className="event_content_area big_content_padding">
                <div className="event_tag_area">
                  <a href="event.html">#FoodCamp</a>
                </div>
                <div className="event_heading_area">
                  <div className="event_heading">
                    <h3><a href="event-details.html">Healthy food and nutritions awreness campaign
                      december</a></h3>
                  </div>
                  <div className="event_date">
                    <img src={Date1} alt="icon" />
                    <h6>20 <span>Dec</span></h6>
                  </div>
                </div>
                <div className="event_para">
                  <p>Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod
                    tempor. Lorem ipsum dolor sit amet, consectetur notted duber
                    adipisicing elit sed do eiusmod tempor.</p>
                </div>
                <div className="event_boxed_bottom_wrapper">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="event_bottom_boxed">
                        <div className="event_bottom_icon">
                          <img src={Map1} alt="icon" />
                        </div>
                        <div className="event_bottom_content">
                          <h5>Location:</h5>
                          <p>Montgomery, Alabama.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="event_bottom_boxed">
                        <div className="event_bottom_icon">
                          <img src={Clock1} alt="icon" />
                        </div>
                        <div className="event_bottom_content">
                          <h5>Starts at:</h5>
                          <p>10 am</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="event_button">
                  <a href="event-details.html" className="btn btn_md btn_theme">Join event</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="event_left_side_wrapper">
              <div className="event_content_area small_content_padding">
                <div className="event_tag_area">
                  <a href="event.html">#FoodCamp</a>
                </div>
                <div className="event_heading_area">
                  <div className="event_heading">
                    <h3><a href="event-details.html">Run for the senior citizens.</a></h3>
                  </div>
                  <div className="event_date">
                    <img src={Date1} alt="icon" />
                    <h6>20 <span>Dec</span></h6>
                  </div>
                </div>
                <div className="event_para">
                  <p>Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod
                    tempor. Lorem ipsum dolor sitr.</p>
                </div>
                <div className="event_boxed_bottom_wrapper">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="event_bottom_boxed">
                        <div className="event_bottom_icon">
                          <img src={Map1} alt="icon" />
                        </div>
                        <div className="event_bottom_content">
                          <h5>Location:</h5>
                          <p>Montgomery, Alabama.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="event_bottom_boxed">
                        <div className="event_bottom_icon">
                          <img src={Clock1} alt="icon" />
                        </div>
                        <div className="event_bottom_content">
                          <h5>Starts at:</h5>
                          <p>10 am</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="event_left_side_wrapper">
              <div className="event_content_area small_content_padding">
                <div className="event_tag_area">
                  <a href="event.html">#FoodCamp</a>
                </div>
                <div className="event_heading_area">
                  <div className="event_heading">
                    <h3><a href="event-details.html">Run for the senior citizens.</a></h3>
                  </div>
                  <div className="event_date">
                    <img src={Date1} alt="icon" />
                    <h6>20 <span>Dec</span></h6>
                  </div>
                </div>
                <div className="event_para">
                  <p>Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod
                    tempor. Lorem ipsum dolor sitr.</p>
                </div>
                <div className="event_boxed_bottom_wrapper">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="event_bottom_boxed">
                        <div className="event_bottom_icon">
                          <img src={Map1} alt="icon" />
                        </div>
                        <div className="event_bottom_content">
                          <h5>Location:</h5>
                          <p>Montgomery, Alabama.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="event_bottom_boxed">
                        <div className="event_bottom_icon">
                          <img src={Clock1} alt="icon" />
                        </div>
                        <div className="event_bottom_content">
                          <h5>Starts at:</h5>
                          <p>10 am</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="event_left_side_wrapper">
              <div className="event_content_area small_content_padding">
                <div className="event_tag_area">
                  <a href="event.html">#FoodCamp</a>
                </div>
                <div className="event_heading_area">
                  <div className="event_heading">
                    <h3><a href="event-details.html">Run for the senior citizens.</a></h3>
                  </div>
                  <div className="event_date">
                    <img src={Date1} alt="icon" />
                    <h6>20 <span>Dec</span></h6>
                  </div>
                </div>
                <div className="event_para">
                  <p>Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod
                    tempor. Lorem ipsum dolor sitr.</p>
                </div>
                <div className="event_boxed_bottom_wrapper">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="event_bottom_boxed">
                        <div className="event_bottom_icon">
                          <img src={Map1} alt="icon" />
                        </div>
                        <div className="event_bottom_content">
                          <h5>Location:</h5>
                          <p>Montgomery, Alabama.</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="event_bottom_boxed">
                        <div className="event_bottom_icon">
                          <img src={Clock1} alt="icon" />
                        </div>
                        <div className="event_bottom_content">
                          <h5>Starts at:</h5>
                          <p>10 am</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UpcomingEvents