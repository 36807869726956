import stripe from "stripe";
import axios from 'axios'
import HelperUtil from './ServiceHelperUtil';

const STRIPE_API_BASE_REST_API_URL = HelperUtil.getBaseURL() + '/stripe-api';

class StripeService{

/*
function createPaymentIntent() {
  // app.post("/create-payment-intent", 
  // async (req, res) => {
  try {
    const paymentIntent = stripe.paymentIntents.create({
      currency: "EUR",
      amount: 1999,
      automatic_payment_methods: { enabled: true },
    });

    // Send publishable key and PaymentIntent details to client
    // res.send({
    //   clientSecret: paymentIntent.client_secret,
    // });
  } catch (e) {
    return e.message
    }
  // });
}
*/

// createPaymentIntent(){
//   return axios.get(STRIPE_API_BASE_REST_API_URL+ '/create-payment-intent' );
// }


//Should NOT BE USED
createPaymentIntent(donationValue){
  return axios.post(STRIPE_API_BASE_REST_API_URL+ '/create-payment' + donationValue);
}


createPaymentIntentWithProjectTypeDetails(donationRequest){
  return axios.post(STRIPE_API_BASE_REST_API_URL+ '/create-payment-intent', donationRequest);
}



}

export default new StripeService();