import React from 'react'
import HeaderNews from '../components/News/HeaderNews'
import NewsSection from '../components/News/NewsSection'

const News = () => {
  return (
    <>
      <HeaderNews />
      <NewsSection />
    </>
  )
}

export default News