import React from 'react'
import Arrow from "../../assets/img/icon/arrow.png"
const HeaderNews = () => {
  return (
    <section id="common_banner_area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="commn_banner_page">
              <h2><span className="color_big_heading">News</span></h2>
              <ul className="breadcrumb_wrapper">
                <li className="breadcrumb_item">
                  <a href="index.html">Home</a></li>
                <li className="breadcrumb_item">
                  <img src={Arrow} alt="img" />
                </li>
                <li className="breadcrumb_item active">News</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeaderNews