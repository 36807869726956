import React from 'react'
import DataTable from './users/TableUsers'
import TableUsersB from './users/TableUsersB'

const MainContent = ({ isSidebarOpen, setIsSidebarOpen }) => {
  return (
    <div className={isSidebarOpen ? " maincontent " : " maincontent mx-3 mx-lg-5 "}>
      <h2>Home Page</h2>
    </div>
  )
}

export default MainContent