import {BehaviorSubject} from 'rxjs';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authService = {
    login,
    logout,
    updateLoggedInUserInfo,
    userHasAuthority,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    }
};

function login(user, accessToken, refreshToken) {
    //store user data and accessToken into the local storage
    let newUser = user;
    delete newUser.authorities
    localStorage.setItem('currentUser', JSON.stringify(newUser));
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    currentUserSubject.next(user);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    currentUserSubject.next(null);
}

function updateLoggedInUserInfo(user) {
    //update user data in the local storage
    localStorage.setItem('currentUser', JSON.stringify(user));
    currentUserSubject.next(user);
}

function userHasAuthority(authority) {
    // console.log(currentUserSubject.value.authorities)
    return currentUserSubject.value?.authorities?.includes(authority);
}