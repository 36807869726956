import axios from 'axios'
import HelperUtil from './ServiceHelperUtil';

const GALLARY_BASE_REST_API_URL = HelperUtil.getBaseURL() + '/gallary';

class CharityProjectServiceImages{
    getGallaryByProjectType(typeId){
        ///projectType/{id}/images
        return axios.get(GALLARY_BASE_REST_API_URL + '/projectType/' + typeId + '/images');
    }

    getGallaryByProjectId(projectId){
        return axios.get(GALLARY_BASE_REST_API_URL + '/project/' + projectId + '/images');
    }

}

export default new CharityProjectServiceImages();