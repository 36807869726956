import axios from 'axios'
import Cookies from 'js-cookie';
import HelperUtil from './ServiceHelperUtil';

const PROJECT_BASE_REST_API_URL = HelperUtil.getBaseURL() + '/charityProjects';
const currentLanguageCode = Cookies.get('i18next') || 'fr'

class CharityProjectService{

    /**
     * Get all projects
     * @returns 
     */
    getAllProjects(){
        return axios.get( PROJECT_BASE_REST_API_URL );
    }

    /**
     * Get Projects based on their Type (typeId) and only get with specific lan
     * @param {*} typeId 
     * @param {*} lang 
     * @returns 
     */
    getProjectsByTypeWithSpecificLang(typeId){

        return axios.get(PROJECT_BASE_REST_API_URL + '/' + typeId + '/' + currentLanguageCode);

    }

    /**
     * Get project by Id
     * @param {*} id 
     * @returns 
     */
    getProjectById(id){
        return axios.get(PROJECT_BASE_REST_API_URL + '/id/' + id );
    }

    /**
     * Get project by id and with specific lang
     * @param {*} id 
     * @param {*} lang 
     * @returns 
     */
    getProjectByIdWithSpecificLang(id){
        return axios.get(PROJECT_BASE_REST_API_URL  + '/projects/id/' + id + '/' + currentLanguageCode);
        ///charityProjects/projects/id/${params}/${currentLanguageCode}`)
    }

   //export const getProjectsSummary = (position) => axiosRequest("get", `/charityProjects/projects-summary/2/${currentLanguageCode}/${position}`)
    getProjectsSummary(projectTypeId, position){
        return axios.get(PROJECT_BASE_REST_API_URL  + '/projects-summary/2/' + currentLanguageCode +'/' + position);
    }

      //export const getProjectsSummary = (position) => axiosRequest("get", `/charityProjects/projects-summary/2/${currentLanguageCode}/${position}`)
      getProjectsSummaryByLangAndPosition( position){
        return axios.get(PROJECT_BASE_REST_API_URL  + '/projects-summary/' + currentLanguageCode +'/' + position);
    }
    
    

}

export default new CharityProjectService();