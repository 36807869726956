import React, { useEffect, useState } from "react"
import Resizer from "react-image-file-resizer";
import { apiClient } from "../../../services/auth/ApiClient";
import { authService } from "../../../services/auth/AuthService";
import { notify } from "../../Toast";
import { ProfileImage } from "../users/ProfileImage";

export const EditProfileImage = () => {

  const [user, setUser] = useState([authService.currentUserValue]);
  const [previewImg, setPreviewImg] = useState(null);
  const [file, setFile] = useState(null);
  const [fileInputRef, setFileInputRef] = useState(null);

  useEffect(() => {
    authService.currentUser.subscribe(x => setUser(x ? { ...x } : x));
  }, []);

  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'file');
  });

  const handleChange = async (event) => {
    const file = event.target.files[0];

    const resized = await resizeFile(file);

    console.log(file);
    setFile(resized);
    console.log(resized);

    setPreviewImg(URL.createObjectURL(resized));
  }

  const uploadFile = async () => {
    console.log(file);

    await apiClient.changeProfileImage(user.id, file);

    setPreviewImg(null);
    setFile(null);
    fileInputRef.value = '';
    authService.updateLoggedInUserInfo(user);
    notify({ info: "User Image updated successfully", status: "success" });

  }

  return (
    <div className='card mt-2 mb-5' style={{ background: "transparent" }}>
      <div className='card-header'>
        <h3 className="text-center">Edit Profile Image</h3>
      </div>
      <div className='card-body'>
        <div className="row justify-content-center">
          <div className='col-4 text-center'>

            {previewImg ?
              <div className='col-4 text-center'>
                <img src={previewImg} className="rounded-circle img-thumbnail"
                  width='180px' height='180px' alt="." />
              </div> : <ProfileImage user={user} width='180px' height='180px' />
            }
          </div>
          <div className='col-4 mb-3'>
            <div className='row mt-3'>
              <label htmlFor="profileImgFile" className="form-label">Choose</label>
              <input className="form-control" type="file" style={{ background: "#eee", height: "45px" }}
                id="profileImgFile"
                ref={(ref) => {
                  setFileInputRef(ref)
                }}
                onChange={handleChange} />
            </div>
            <div className="row">
              <button type="submit" className="btn btn-primary text-light list-btn mt-2" disabled={!file}
                onClick={uploadFile}>
                Submit
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}