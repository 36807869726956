import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import HelperUtil from '../helpers/HelperUtil';
import { t } from 'i18next';
import FileDownloaderHelperUtil from '../helpers/FileDownloaderHelperUtil';
import Header from '../components/Home/Header';
import "../styles/Home.css";
import UpcomingEvents from '../components/Home/UpcomingEvents';
import PartnerArea from '../components/Home/PartnerArea';
import CounterArea from '../components/Home/CounterArea';
import ProjectsSummaryComponent from '../components/Home/ProjectsSummaryComponent';
import ProjectTypesComponent from '../components/Home/ProjectTypesComponent';
import AboutUsComponent from '../components/Home/AboutUsComponent';
import DonationMotivationComponent from '../components/Home/DonationMotivationComponent';

function Home() {

  return ( 
    <>
      <Header />
      <ProjectTypesComponent />
      <AboutUsComponent />
      <ProjectsSummaryComponent position = 'ABOVE' />
      {/* <UpcomingEvents /> */}
      <DonationMotivationComponent />
      {/* <PartnerArea /> */}
      {/* <CounterArea /> */}
      {/* <ProjectsShow /> */}
      <ProjectsSummaryComponent  position = 'BELOW' />
    </>
  );
}

export default Home;
