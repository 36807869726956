import React from 'react'
import EventBig from "../../assets/img/event/event-big.png"
import SmallDetails1 from "../../assets/img/causes/details-small-1.png"
import SmallDetails2 from "../../assets/img/causes/details-small-2.png"

import Tag from "../../assets/img/icon/tag.png"
import Map1 from "../../assets/img/icon/map.png"
import Cal from "../../assets/img/icon/cal.png"
import Email from "../../assets/img/icon/email.png"
import Phone from "../../assets/img/icon/phone.png"

import RecDonet1 from "../../assets/img/sidebar/rec-donet-1.png"
import RecDonet2 from "../../assets/img/sidebar/rec-donet-2.png"
import RecDonet3 from "../../assets/img/sidebar/rec-donet-3.png"

import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const EventDetailsArea = () => {
  return (
    <>
      <section id="trending_causes_main" className="section_padding">
        <div className="container">
          <div className="row" id="counter">
            <div className="col-lg-8">
              <div className="details_wrapper_area">
                <div className="details_big_img">
                  <img src={EventBig} alt="img" />
                </div>
                <div className="details_text_wrapper">
                  <a href="events.html" className="tags_noted">#FoodCamp</a>
                  <h2>Healthy food and nutritions awreness campaign december</h2>
                  <p>
                    Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum. There are many
                    variations of passages of Lorem Ipsum available but the majority.
                  </p>
                  <p>
                    If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't
                    anything embarrang hidden in the middle of text. All the Lorem Ipsum generators on the
                    Internet tend to repeat predefined chunks as necessary, making this the first true.
                  </p>
                  <h3>We want to ensure the education for the kids.</h3>
                  <p>
                    Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum. There are many
                    variations of passages of Lorem Ipsum available, but the majority have alteration in
                    some injected or words which don't look even slightly believable.
                  </p>
                  <ul>
                    <li><i className="fas fa-circle"></i> Lorem ipsum dolor sit amet, cibo mundi ea duo, vim
                      exerci phaedrum.</li>
                    <li><i className="fas fa-circle"></i> There are many variations of passages of Lorem Ipsum.
                    </li>
                    <li><i className="fas fa-circle"></i> Available but the majority have alteration in some
                      injected or words.</li>
                    <li><i className="fas fa-circle"></i> There are many variations of passages of Lorem Ipsum
                      which don't look even slightly
                      believable.</li>
                  </ul>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="details_small_img">
                        <img src={SmallDetails1} alt="img" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="details_small_img">
                        <img src={SmallDetails2} alt="img" />
                      </div>
                    </div>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, cibo mundi ea duo, vim exerci phaedrum. There are many
                    variations of passages of Lorem Ipsum available, but the majority have alteration in
                    some injected or words which don't look even slightly believable.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar_first">
                <div className="sidebar_boxed">
                  <div className="sidebar_heading_main">
                    <h3>Event details</h3>
                  </div>
                  <div className="event_details_list">
                    <ul>
                      <li><img src={Tag} alt="icon" /> Category: <span>Food and
                        nutrition</span>
                      </li>
                      <li><img src={Map1} alt="icon" /> Location: <span>990 Green Hill
                        Bronx, NY 10458.</span></li>
                      <li><img src={Cal} alt="icon" /> Date: <span>20 Dec, 2021</span>
                      </li>
                      <li><img src={Email} alt="icon" /> Mail:
                        <span>registermail@domain.com</span>
                      </li>
                      <li><img src={Phone} alt="icon" /> Phone: <span>+880 123 456
                        789</span>
                      </li>
                    </ul>
                    <div className="register_now_details">
                      <a href="#!" className="btn btn_theme btn_md w-100">Register now</a>
                    </div>
                  </div>
                </div>
                <div className="project_recentdonet_wrapper sidebar_boxed">
                  <div className="sidebar_heading_main">
                    <h3>Event organizer</h3>
                  </div>
                  <div className="recent_donet_item">
                    <div className="recent_donet_img">
                      <a href="cause-details.html">
                        <img src={RecDonet1} alt="img" /></a>
                    </div>
                    <div className="recent_donet_text">
                      <div className="sidebar_inner_heading">
                        <h4><a href="cause-details.html">Mike richard</a></h4>
                      </div>
                      <p>Managing director</p>
                      <h6>Care NGO ltd.</h6>
                    </div>
                  </div>
                  <div className="recent_donet_item">
                    <div className="recent_donet_img">
                      <a href="cause-details.html">
                        <img src={RecDonet2} alt="img" />
                      </a>
                    </div>
                    <div className="recent_donet_text">
                      <div className="sidebar_inner_heading">
                        <h4><a href="cause-details.html">Jenifar lawrence</a></h4>
                      </div>
                      <p>Entrepreneur</p>
                      <h6>Own business</h6>
                    </div>
                  </div>
                  <div className="recent_donet_item">
                    <div className="recent_donet_img">
                      <a href="cause-details.html">
                        <img src={RecDonet3} alt="img" />
                      </a>
                    </div>
                    <div className="recent_donet_text">
                      <div className="sidebar_inner_heading">
                        <h4><a href="cause-details.html">David jovan</a></h4>
                      </div>
                      <p>Manager</p>
                      <h6>ABC Company</h6>
                    </div>
                  </div>
                </div>
                <div className="share_causes_wrapper sidebar_boxed">
                  {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"
                    height="350" style="border:0;width: 100%;"></iframe> */}
                </div>
                <div className="share_causes_wrapper sidebar_boxed">
                  <div className="sidebar_heading_main">
                    <h3>Share causes</h3>
                  </div>
                  <div className="social_icon_sidebar">
                    <ul>
                      <li><a href="#!">
                        {/* <img src="assets/img/icon/facebook.png" alt="icon" /> */}
                        <FacebookIcon />
                      </a></li>
                      <li><a href="#!">
                        {/* <img src="assets/img/icon/instagram.png" alt="icon" /> */}
                        <TwitterIcon />
                      </a></li>
                      <li><a href="#!">
                        {/* <img src="assets/img/icon/twitter.png" alt="icon" /> */}
                        <InstagramIcon />
                      </a></li>
                      <li><a href="#!">
                        {/* <img src="assets/img/icon/linkedin.png" alt="icon" /> */}
                        <LinkedInIcon />
                      </a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EventDetailsArea