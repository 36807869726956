import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
import Image1 from "../../assets/img/headerImage.png"
import Image2 from "../../assets/img/home/camp.jpg"
import Image3 from "../../assets/img/home/gaza.jpg"
import { Link } from 'react-router-dom';


const SliderHeader = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,        // Enable autoplay
    autoplaySpeed: 3000,
  };

  return (
    <Slider {...settings}>

      <div className="container d-flex" >
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4">
            <div className="banner_one_text">
              <h1 className='mb-4'>
                {t("gaza_sos")}
              </h1>
              {/* <p className='mb-3'>{t("header_main_p")}</p> */}

              <Link to="/supportUs" className="btn btn_md btn_theme"
                onClick={() => scrollToTop()} style={{ borderRadius: "25px", fontSize: "18px" }}>{t("donate_area_donate_now")}</Link>

            </div>
          </div>
          <div className="col-lg-7">
            <div className="banner_one_img">
              <img src={Image3} style={{ borderRadius: 3 }} alt="img" />

            </div>
          </div>
        </div>
      </div>

      <div className="container d-flex" >
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-7">
            <div className="banner_one_img">
              <img src={Image2} style={{ borderRadius: 3 }} alt="img" />

            </div>
          </div>

          <div className="col-lg-4">
            <div className="banner_one_text">
              <h1 className='mb-4 h1'>
                {t("tenants_text")}
              </h1>
              {/* <p className='mb-3'>{t("header_main_p")}</p> */}
              <Link to="/supportUs" className="btn btn_md btn_theme"
                onClick={() => scrollToTop()} style={{ borderRadius: "25px", fontSize: "18px" }}>{t("donate_area_donate_now")}</Link>

            </div>
          </div>

        </div>
      </div>

      <div className="container d-flex" >
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6">
            <div className="banner_one_text">
              <h1>
                {t("header_main")}
              </h1>
              <p>{t("header_main_p")}</p>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="banner_one_img">
              <img src={Image1} alt="img" />

              <div className="banner_element">
                <div className='element_2 shape-2 bg-shap-header2'>
                  <span className='bg-shap-header-text'>{t("header_main_num_orphan")}</span>
                </div>
                <div className='element_3 shape-3 bg-shap-header'>
                  <span className='bg-shap-header-text'>{t("header_main_num_person")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
}

export default SliderHeader