import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import SwiperImageProject from '../components/SwiperImageProject'


import CharityProjectService from '../services/CharityProjectService';
import Convert64ToImage from '../services/Convert64ToImage';
import CharityProjectServiceImages from '../services/CharityProjectServiceImages';

import Cookies from "js-cookie";
import Loader from '../components/Loader';
import EmailService from '../services/EmailService';


const currentLanguageCode = Cookies.get('i18next') || 'fr'


const ProjectDetailsPage = () => {
  const { t } = useTranslation();
  const [mainImage, setMainImage] = useState('')
  const [projectName, setProjectName] = useState('')
  const [description, setDescription] = useState('')
  const [projectImages, setProjectImages] = useState('')
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingSwiper, setIsLoadingSwiper] = useState(true)


  useEffect(() => {
    CharityProjectService.getProjectByIdWithSpecificLang(id).then((response) => {
      setMainImage(response.data?.mainImage)
      setProjectName(response.data?.projectName)
      setDescription(response.data?.description)
      setIsLoading(false)
      // setProjectImages(response.data?.projectImages)
      // console.log(response.data);
    }).catch(error => {
      setIsLoading(false)
      console.log(error);
    })

    CharityProjectServiceImages.getGallaryByProjectId(id)
      .then((response) => {
        setProjectImages(response.data)
        // console.log(response.data)
        setIsLoadingSwiper(false)
      }).catch(error => {
        console.log(error);
        setIsLoadingSwiper(false)
      })
  }, [])


  if (isLoading) {
    return (
      <><Loader /></>
    )
  }


  return (
    <div>

      <div className="d-flex flex-column align-items-center w-75 mx-auto">
        {/* 
        <div className='p-3'>
          <Convert64ToImage mainImage={mainImage} />
        </div> 
        */}
        <div className='w- mx-auto my-5'>
          <h2 className='main-heading'>{projectName}</h2>
          <div dangerouslySetInnerHTML={{ __html: description }} />
          {
            // <div className='p-project' >{description}.</div>
            //  <p className='p-project' >{motivation}.</p>
            // <p className='p-project' >{projectGoals}.</p>
          }

        </div>
      </div>
      {isLoadingSwiper ?
        <Loader />
        :
        <SwiperImageProject images={projectImages} />
      }
    </div>



  )
}

export default ProjectDetailsPage
