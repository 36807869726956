import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";


import CharityProjectService from '../../services/CharityProjectService';
import Convert64ToImage from '../../services/Convert64ToImage';

import Cookies from "js-cookie";
import Loader from '../Loader';


const currentLanguageCode = Cookies.get('i18next') || 'fr'

const ProjectsSummaryComponent = ({ position }) => {
  const { t } = useTranslation();
  const [projectsSummary, setProjectsSummary] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {

    CharityProjectService.getProjectsSummaryByLangAndPosition(position).then((response) => {
      setProjectsSummary(response.data)
      setIsLoading(false)
      // console.log(response.data);
    }).catch(error => {
      setIsLoading(false)
      console.log(error);
    })
  }, [])

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  if (isLoading) {
    return (
      <><Loader /></>
    )
  }

  return (
    <>
      <section id="trending_causes" className="section_after section_padding bg-color projects-type">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="section_heading">
                {/* <h3>{t("trending_causes_header2_section_2")}</h3> */}
                {position === 'ABOVE' ? (
                  <h2>{t("trending_causes_header4_section_2")}</h2>
                ) :
                  (<h2>{t("trending_causes_header4")}</h2>)
                }
              </div>
            </div>
          </div>

          <div className="row" id="counter">
            {projectsSummary.map((el) =>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 mb-3" key={el.projectId}>
                <div className="case_boxed_wrapper">
                  <div className="case_boxed_img">
                    <Link to={`/projectDetails/${el.projectId}`} onClick={scrollToTop} >
                      <Convert64ToImage mainImage={el.mainImage} />
                    </Link>
                    {/* <span className="causes_badge bg-theme">{t("trending_causes_Food_water")}</span> */}
                  </div>
                  {
                    currentLanguageCode === 'ar' ?
                      <div className="causes_boxed_text_ar">
                        <Link to={`/projectDetails/${el.projectId}`} onClick={scrollToTop}>
                          <h3 className='cursor-pointer pb-2'>
                            {el.projectName}
                          </h3>
                          <p className='cursor-pointer'>{el.shortDescription.split(" ").slice(0, 20).join(" ")}.... &nbsp;
                            <span style={{ fontWeight: 600, color: "#272f59" }}>{t('more_label')}</span> </p>
                        </Link>
                        {/* <div className="causes_boxed_bottom_wrapper"></div> */}
                      </div>
                      :
                      <div className="causes_boxed_text">
                        <Link to={`/projectDetails/${el.projectId}`} onClick={scrollToTop}>
                          <h3 className='cursor-pointer'>
                            {el.projectName}
                          </h3>
                          <p className='cursor-pointer'>{el.shortDescription.split(" ").slice(0, 20).join(" ")}.... &nbsp;
                            <span style={{ fontWeight: 600, color: "#272f59" }}>{t('more_label')}</span> </p>
                        </Link>
                        {/* <div className="causes_boxed_bottom_wrapper"></div> */}
                      </div>
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>



  )
}

export default ProjectsSummaryComponent
