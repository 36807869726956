import React, { useEffect, useState } from "react"
import { apiClient } from "../../../services/auth/ApiClient";

export const ProfileImage = (props) => {

    const user = props.user;
    const width = props.width ? props.width : '50';
    const height = props.height ? props.height : '50';

    const [profileImg, setProfileImg] = useState({});


    useEffect(() => {
        if (user?.id) {
            apiClient
                .getProfileImage(user.id)
                .then(img => setProfileImg(img));
        }
    }, [user]);

    return (

        <img src={profileImg} className="rounded-circle img-thumbnail" width={width} height={height}
            alt="..." />

    );
}


