import React from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PieChartOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import profileImage from "../../assets/img/logo.png";
import { authService } from "../../services/auth/AuthService";
import LogoutIcon from '@mui/icons-material/Logout';

const navItems = [
  {
    text: "Admin",
    icon: <AdminPanelSettingsOutlined />,
  },
  {
    text: "users",
    icon: <AdminPanelSettingsOutlined />,
  },
  {
    text: "Roles",
    icon: <HomeOutlined />,
  },
  {
    text: "profile",
    icon: <ShoppingCartOutlined />,
  },
  {
    text: "Management",
    icon: null,
  },
  {
    text: "Performance",
    icon: <TrendingUpOutlined />,
  },
  {
    text: "Log Out",
    icon: <LogoutIcon />,
  },
];

const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isMobile,
}) => {

  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  function handleClose() {
    authService.logout();
    navigate("/admin/login"); //Go to Login page after Logout
  }

  return (
    <Box component="nav" className="sidebar">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"

          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSixing: "border-box",
              borderWidth: isMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 1rem 2rem 2rem">
              <FlexBetween>
                {/* color={theme.palette.secondary.main} */}
                <Box
                  component="img"
                  alt="profile"
                  src={profileImage}
                  height="40px"
                  width="40px"
                  borderRadius="50%"
                  sx={{ objectFit: "cover" }}
                />
                <Box display="flex" alignItems="center" justifyContent="flex-start">
                  <Typography variant="h4" fontWeight="bold">
                    De Hope
                  </Typography>
                </Box>
                <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                  <ChevronLeft />
                </IconButton>
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon }) => {
                // if there is no icon make the Divider word
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }

                if (text === "Log Out") {
                  return (
                    <>
                      <Box margin=".52rem 0">
                        <Divider />
                      </Box>
                      <Box display={"flex"} alignItems="center" marginTop={"10px"}>
                        <ListItemIcon
                          sx={{
                            color: theme.palette.secondary[100],
                            m: "1.1rem 0 1rem 3rem"
                          }}
                        >
                          <LogoutIcon />
                        </ListItemIcon>
                        <Typography onClick={handleClose} sx={{ color: theme.palette.secondary[100] }}>
                          Log Out
                        </Typography>
                      </Box>
                    </>
                  );
                }

                const lcText = text.toLowerCase();

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setActive(lcText);
                        if (lcText == "admin") navigate(`/admin`);
                        else if (lcText == "users") navigate(`/admin/users/all`);
                        else if (lcText == "roles") navigate(`/admin/roles/getAll`);
                        else if (lcText == "profile") navigate(`/admin/profile/me`);
                        // else navigate(`roles/${lcText}`);
                      }}
                      sx={{
                        backgroundColor:
                          active == lcText
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active == lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;