import React from 'react'
import Header from '../components/causes/Header'
import TrendingCausesMain from '../components/causes/TrendingCausesMain'

const Causes = () => {
  return (
    <>
      <Header />
      <TrendingCausesMain />
    </>
  )
}

export default Causes