import axios from 'axios'
import HelperUtil from './ServiceHelperUtil';
import Cookies from "js-cookie"




const PLAIN_WEB_PAGE_BASE_REST_API_URL = HelperUtil.getBaseURL() + '/plainWebPage';
const currentLanguageCode = Cookies.get('i18next') || 'fr'

class PlainWebPageService {

    /**
     * Get All Plain web pages
     * @returns  'active' and 'inactive' pages in all lang
     */
    getALLPlainWebPages() {
        return axios.get(PLAIN_WEB_PAGE_BASE_REST_API_URL);
    }

    /**
     * Get pages with specific lang
     * @param {*} lang 
     * @returns 
     */
    getPlainWebPagesWithSpecificLang() {
        return axios.get(PLAIN_WEB_PAGE_BASE_REST_API_URL + '/' + currentLanguageCode);
    }


    /**
     * Get Active pages
     * 
     * @param {*} lang 
     * @returns 
     */
    getActivePlainWebPagesWithSpecificLang() {
        return axios.get(PLAIN_WEB_PAGE_BASE_REST_API_URL + '/active/' + currentLanguageCode);
    }


    /**
     * Get Page with id
     * @param {*} id 
     * @returns 
     */
    getPlainWebPageById(id) {
        return axios.get(PLAIN_WEB_PAGE_BASE_REST_API_URL + '/id/' + id);
    }

    /**
     * Get page with id in specific lang
     * @param {*} id 
     * @param {*} lang 
     * @returns 
     */
    getPlainWebPageByIdWithSpecificLang(id) {
        return axios.get(PLAIN_WEB_PAGE_BASE_REST_API_URL + '/' + id + '/' + currentLanguageCode);
    }

    getPlainWebPageDetailsWithSpecificLangAndId(id) {
        return axios.get(PLAIN_WEB_PAGE_BASE_REST_API_URL + '/details/' + id + '/' + currentLanguageCode);
    }

}

export default new PlainWebPageService();