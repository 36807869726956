import Cookies from "js-cookie"
import axiosRequest, { axiosRequestDocument } from "./request"

const currentLanguageCode = Cookies.get('i18next') || 'fr'

/*
export const getAboutPage = () => axiosRequest("get", `/plainWebPage/1/${currentLanguageCode}`)
export const getSupportPage = () => axiosRequest("get", `/plainWebPage/2/${currentLanguageCode}`)
export const getVolunteerWithUs = () => axiosRequest("get", `/plainWebPage/3/${currentLanguageCode}`)
*/
//Lighter response
export const getAboutPage = () => axiosRequest("get", `/plainWebPage/details/1/${currentLanguageCode}`)
export const getSupportPage = () => axiosRequest("get", `/plainWebPage/details/2/${currentLanguageCode}`)
export const getVolunteerWithUs = () => axiosRequest("get", `/plainWebPage/details/3/${currentLanguageCode}`)


export const getTypeProjects = () => axiosRequest("get", `/charityProjects/types/${currentLanguageCode}`)
//This heavy request -- avoid it
//export const getProjects = () => axiosRequest("get", `/charityProjects/projects/2/${currentLanguageCode}`)
//Light Response


/*
Moved to Service
*/
//export const getProjectsSummary = (position) => axiosRequest("get", `/charityProjects/projects-summary/2/${currentLanguageCode}/${position}`)

export const getProjectById = (params) => axiosRequest("get", `/charityProjects/projects/id/${params}/${currentLanguageCode}`)

//Slow / heavy response
//export const getPublicationPage = () => axiosRequest("get", `/publication/active/${currentLanguageCode}`)

//Faster / Lighter response
export const getPublicationPage = () => axiosRequest("get", `/publication/summary/active/${currentLanguageCode}`)

export const getProjectsWithSameType = (id) => axiosRequest("get", `/gallary/projectType/${id}/images`)





// This Is For Get Document PDF (Please don't use It ) XXXXXX
export const getPdf = (id) => axiosRequestDocument("get", `/publication/publication-download/${id}`)