import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import Arrow from "../assets/img/icon/arrow.png"
import GalleryGrid from '../components/GalleryGrid'
import { getTypeProjects } from '../services/api/user'
import CharityProjectTypeService from '../services/CharityProjectTypeService'



const Gallery = () => {

  // const { isLoading, error, data } = useQuery('typeProjects',
  //   () => (getTypeProjects())
  // )

  const [projectsTypes, setProjectsTypes] = useState([])

  useEffect(() => {

    CharityProjectTypeService.getProjectTypesWithSpecificLang().then((response) => {
      setProjectsTypes(response.data)
      // console.log(response.data);
    }).catch(error => {
      console.log(error);
    })
  }, [])

  let typeProject = projectsTypes || ""
  return (
    <>
      <GalleryGrid typeProject={typeProject} />
    </>
  )
}

export default Gallery