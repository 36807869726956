import React, { useEffect, useState } from "react"
import { apiClient } from "../../../services/auth/ApiClient";
import { authService } from "../../../services/auth/AuthService";
import { notify } from "../../Toast";

export const EditInfo = () => {

    const [user, setUser] = useState([authService.currentUserValue]);
    const [flag, setFlag] = useState(true)

    useEffect(() => {
        authService.currentUser.subscribe(x => setUser(x));
    }, []);

    function handleSubmit(event) {
        // Prevent default behavior
        event.preventDefault();

        let updatedUser = {
            id: user.id,
            username: user.username,
            email: user.email,
            firstname: event.target.firstname.value,
            lastname: event.target.lastname.value,
            city: event.target.city.value,
            address: event.target.address.value
        };
        console.log(updatedUser);
        apiClient.updateUser(updatedUser)
            .then(updated => {
                console.log("Response from backend: " + JSON.stringify(updated));
                if (updated) {
                    authService.updateLoggedInUserInfo(updated);
                    notify({info: "User info updated successfully", status: "success"});
                } else {
                    console.log("Can not update: " + updated);
                }
            });
    }

    return (
        <>{user &&
            <div className='card mt-2' style={{ background: "transparent" }}>
                <div className='card-header'>
                    <h3 className="text-center">Edit INFO</h3>
                </div>
                <div className='card-body border'>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="form-group mt-3">
                                <label>Firstname</label>
                                <input
                                    type="text"
                                    className={`form-control mt-1 ${flag ? "bg-input-readOnly" :  "bg-light" }`}
                                    placeholder="Firstname"
                                    name="firstname"
                                    defaultValue={user.firstname}
                                    readOnly={flag}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label>Lastname</label>
                                <input
                                    type="text"
                                    className={`form-control mt-1 ${flag ? "bg-input-readOnly" :  "bg-light" }`}
                                    placeholder="Lastname"
                                    name="lastname"
                                    defaultValue={user.lastname}
                                    readOnly={flag}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label>City</label>
                                <input
                                    type="text"
                                    className={`form-control mt-1 ${flag ? "bg-input-readOnly" :  "bg-light" }`}
                                    placeholder="City"
                                    name="city"
                                    defaultValue={user.city}
                                    readOnly={flag}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label>Address</label>
                                <input
                                    type="text"
                                    className={`form-control mt-1 ${flag ? "bg-input-readOnly" :  "bg-light" }`}
                                    placeholder="Address"
                                    name="address"
                                    defaultValue={user.address}
                                    readOnly={flag}
                                />
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                                <button type="button" className="btn btn-primary list-btn btn-form" onClick={() => setFlag(prev => !prev)}>
                                    Edit
                                </button>

                                <button type="submit" className="btn btn-primary list-btn btn-form" >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        }
        </>
    )
}