import React from 'react'
import { useState } from 'react'

import { BsFillInfoCircleFill, BsPencilFill } from "react-icons/bs"
import { BsShieldShaded } from "react-icons/bs"
import { Link } from 'react-router-dom'
import { notify } from '../../Toast'

const UsersInfo = ({ user }) => {

  const [showAuthorities, setShowAuthorities] = useState(false)
  const [isShowDetails, setShowDetails] = useState(false);

  return (
    <div className="d-flex flex-column " style={{ border: "0.5px solid #555" }} key={user.id}>

      <div className="d-flex w-100 justify-content-between p-2">
        <div className="p-2 w-25">{user.username}</div>
        <div className="p-2 w-25">{user.email}</div>
        <div className="p-2 w-25">{user.roles.length == 1 ? user.roles[0] : user.roles.map(el => <span key={Math.ceil(Math.random())}>{el}</span>)}</div>
        <div className="p-2 w-25 d-flex">
          <button type="button" title="Show Details" className="btn btn-link btn-sm btn-rounded" onClick={() => setShowDetails(prev => !prev)}>
            <BsFillInfoCircleFill />
          </button>
          <button type="button" title="Show Authorities" className="btn btn-link btn-sm btn-rounded" onClick={() => setShowAuthorities(prev => !prev)}>
            <BsShieldShaded />
          </button>
          <Link to={`/admin/userProfile/${user.id}`} title="Show Profile User" className="btn btn-link btn-sm btn-rounded">
            <BsPencilFill />
          </Link>
        </div>
      </div>
      {/* 1 */}
      <div className='row mt-2' style={{ margin: "0 0px 30px 20px" }} hidden={!isShowDetails}>
        <div className='col-sm-12 col-xl-6 border border-secondary'>
          {user.city}
        </div>
        <div className='col-sm-12 col-xl-6 border border-secondary'>
          {user.address}
        </div>
      </div>
      {/* 2 style={{ width: "fit-content" }} */}
      <div className='row px-4 pt-1 pb-4' hidden={!showAuthorities} >
        <h5 className='mb-2'>Authorities</h5>
        {user?.authorities?.map(authority => (
          <div className='col-sm-6 col-md-4 col-xl-3 border border-secondary' key={authority}>
            {authority}
          </div>
        ))}
      </div>

    </div>
    // <tr >
    //   <td>
    //     <div className="d-flex align-items-center">
    //       <div className="ms-3">
    //         <p className="fw-bold mb-1">{user.username}</p>
    //       </div>
    //     </div>
    //   </td>
    //   <td>
    //     <p className="text-muted mb-0">{user.email}</p>
    //   </td>
    //   <td>{user.roles.length == 1 ? user.roles[0] : user.roles.map(el => <span>{el}</span>)}</td>
    //   <td>
    //     <button type="button" className="btn btn-link btn-sm btn-rounded">
    //       <BsFillInfoCircleFill />
    //     </button>
    //     <button type="button" className="btn btn-link btn-sm btn-rounded" onClick={() => setShowAuthorities(prev => !prev)}>
    //       <BsShieldShaded />
    //     </button>
    //     <button type="button" className="btn btn-link btn-sm btn-rounded">
    //       <BsPencilFill />
    //     </button>
    //   </td>
    //   {
    //     // showAuthorities &&
    //     <td className='row' hidden={!showAuthorities}>
    //       <h5>Authorities</h5>
    //       {user.authorities?.map(authority => (
    //         <div className='col-sm-6 col-md-4 col-xl-3 border border-secondary' key={authority.id}>
    //           {authority}
    //         </div>
    //       ))}
    //     </td>
    //   }
    // </tr>
  )
}

export default UsersInfo