import React from "react"
import { BsTrashFill } from "react-icons/bs"
import { apiClient } from "../../../services/auth/ApiClient";
import { authService } from "../../../services/auth/AuthService";

const RolesViewOne = (props) => {

  const role = props.role;

  const deleteRole = async () => {
    if (window.confirm(`Are you sure to delete role ${role.name}?`)) {
      await apiClient.deleteRole(role);
      props.setLastModifiedRole(role);
    }
  }

  return (
    <li className="list-group-item">
      <div className={'row mb-2'}>
        <div className="col-11">
          <h5>{role.name}</h5>
        </div>
        <div className="col-1">
          {authService.userHasAuthority('role.delete') &&
            <button className={`border-0 bg-transparent h4 ${localStorage.getItem("mode") == "dark" ? "text-light" : "main-color-dash"}`} onClick={() => deleteRole()}>
              <BsTrashFill />
            </button>
          }
        </div>
      </div>
      <div className="row">
        {role.authorities.map(authority => (
          <div
            className={`col-sm-6 col-md-4 col-xl-3 border border-secondary py-2 ${localStorage.getItem("mode") == "dark" ? "text-light" : "main-color-dash"}`}
            key={authority.id}>{authority.authority}</div>
        )
        )}
      </div>
    </li>
  )
}

export default RolesViewOne