import React, { useEffect, useState } from 'react';
import { EditInfo } from '../../components/dashboard/profile/EditInfo';
import { EditProfileImage } from '../../components/dashboard/profile/EditProfileImage';
import User1Info from '../../components/dashboard/users/User1Info';
import { authService } from '../../services/auth/AuthService';


const Profile = ({ isSidebarOpen }) => {

  const [loggedInUser, setLoggedInUser] = useState([authService.currentUserValue]);
  // console.log(authService.currentUserValue)

  useEffect(() => {
    authService.currentUser.subscribe(x => setLoggedInUser(x ? { ...x } : x));
  }, []);

  return (
    <div className={`userPage ${isSidebarOpen ? "userPage-open" : "userPage-close mx-3 mx-lg-5"}`}>
      <div className='row'>
        <div className="col-12">
          <EditProfileImage />
        </div>
      </div>
      <div className='row'>
        <User1Info user={loggedInUser}
          isShowDetails={true}
          isShowAuthorities={true} />
      </div>
      <div className='row justify-content-around py-5'>
        <div className='col-sm-6 col-lg-5 '>
          <EditInfo />
        </div>
      </div>
    </div>
  )

}


export default Profile;