import React from 'react'
import Image1 from "../../assets/img/partner/logo-1.png"
import Image2 from "../../assets/img/partner/logo-2.png"
import Image3 from "../../assets/img/partner/logo-3.png"
import Image4 from "../../assets/img/partner/logo-4.png"
import Image5 from "../../assets/img/partner/logo-5.png"
import Image6 from "../../assets/img/partner/logo-6.png"
import Image7 from "../../assets/img/partner/logo-7.png"

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

const PartnerArea = () => {
  return (

    <section id="partner_area">
      <h2 className="d-none">Heading</h2>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Swiper
              className="partner_slider_wrapper owl-theme owl-carousel"
              spaceBetween={5}
              slidesPerView={7}
              loop={true}
              autoplayhoverpause="true"
              autoplay={true}
              smartspeed = {10}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide className="partner_logo">
                <a href="#!">
                  <img src={Image1} alt="img" />
                </a>
              </SwiperSlide>
              <SwiperSlide className="partner_logo">
                <a href="#!">
                  <img src={Image2} alt="img" />
                </a>
              </SwiperSlide>
              <SwiperSlide className="partner_logo">
                <a href="#!">
                  <img src={Image3} alt="img" />
                </a>
              </SwiperSlide>
              <SwiperSlide className="partner_logo">
                <a href="#!">
                  <img src={Image4} alt="img" />
                </a>
              </SwiperSlide>
              <SwiperSlide className="partner_logo">
                <a href="#!">
                  <img src={Image5} alt="img" />
                </a>
              </SwiperSlide>
              <SwiperSlide className="partner_logo">
                <a href="#!">
                  <img src={Image6} alt="img" />
                </a>
              </SwiperSlide>
              <SwiperSlide className="partner_logo">
                <a href="#!">
                  <img src={Image7} alt="img" />
                </a>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PartnerArea