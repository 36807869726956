import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { authService } from '../../services/auth/AuthService';

function Protected({ isSignedIn, children }) {
  const [loggedInUser, setLoggedInUser] = useState(authService.currentUserValue);
  // const [isAdmin, SetIsAdmin] = useState(authService.currentUserValue.roles.includes("ADMIN") || false)

  useEffect(() => {
    authService.currentUser.subscribe(x => setLoggedInUser(x));

    // SetIsAdmin(authService.currentUserValue.roles.includes("ADMIN"))

  }, [authService]);


  const isAuthenticated = !!loggedInUser;
  // console.log("🚀 ~ file: Protected.jsx:16 ~ Protected ~ isAuthenticated", isAuthenticated);


  useEffect(() => {
    // if (!isAuthenticated) {
    //   navigate("/admin/login");
    // }    
  }, [isAuthenticated]);


  if (!isAuthenticated) {
    return <Navigate to={`/admin/login`} replace />
  }
  else {
    return children
  }

}
export default Protected