import React, { useEffect, useState } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import FlexBetween from "./FlexBetween";
import { useDispatch } from "react-redux";
import profileImage from "../../assets/img/logo.png";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  InputBase,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { authService } from "../../services/auth/AuthService";
import { useNavigate } from "react-router-dom";
import { MdDarkMode, MdOutlineDarkMode } from "react-icons/md"
import { ProfileImage } from "./users/ProfileImage";
import { apiClient } from "../../services/auth/ApiClient";


const NavDashboard = ({ isSidebarOpen, setIsSidebarOpen, setmode, mode }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [profileImg, setProfileImg] = useState({});
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const [loggedInUser, setLoggedInUser] = useState([authService.currentUserValue]);

  useEffect(() => {
    authService.currentUser.subscribe(x => setLoggedInUser(x ? { ...x } : x));
  }, []);


  useEffect(() => {
    if (loggedInUser?.id) {
      apiClient
        .getProfileImage(loggedInUser.id)
        .then(img => setProfileImg(img));
    }
  }, [loggedInUser]);



  const toggleColor = (e) => {
    localStorage.setItem("mode", e)
    setmode(prev => !prev)
  }


  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
        marginLeft: "auto",
        transition: ".4s all"
      }}
      className={isSidebarOpen ? " w-full-close " : " w-full-open "}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(prev => !prev)}>
            <MenuIcon />
          </IconButton>
          <FlexBetween
            backgroundColor={theme.palette.background.alt}
            borderRadius="9px"
            gap="3rem"
            p="0.1rem 1.5rem"
          >
            <InputBase placeholder="Search..." />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">

          <Box>
            {
              mode ? <MdDarkMode className="h3 cursor_pointer_moh m-0" onClick={() => toggleColor("light")} />
                : <MdOutlineDarkMode className="h3 cursor_pointer_moh m-0" onClick={() => toggleColor("dark")} />
            }
          </Box>
          <Typography sx={{ color: theme.palette.secondary[100], }}>{JSON.parse(localStorage.getItem('currentUser')).username}</Typography>
          <img src={profileImg} className="rounded-circle img-thumbnail border-0 p-0 bg-transparent" width={45} height={45}
            alt="..." />

        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default NavDashboard;