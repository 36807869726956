import React from 'react'
import BlogImg from "../../assets/img/blog/blog-1.png"
const NewsSection = () => {
  return (
    <section id="main_blog_area" className="section_padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="section_heading">
              <h3>Our latest news</h3>
              <h2>Read and <span className="color_big_heading">explore</span> your
                knowledge through our news
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="blog_card_wrapper">
              <div className="blog_card_img">
                <a href="news-details.html">
                  <img src={BlogImg} alt="img" />
                </a>
              </div>
              <div className="blog_card_text">
                <div className="blog_card_tags">
                  <a href="news-details.html">#FoodCamp</a>
                </div>
                <div className="blog_card_heading">
                  <h3><a href="news-details.html">Healthy food and nutritions awreness campaign
                    december</a></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod...</p>
                </div>
                <div className="blog_boxed_bottom_wrapper">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="blog_bottom_boxed">
                        <div className="blog_bottom_icon">
                          <img src="assets/img/icon/cal.png" alt="icon" />
                        </div>
                        <div className="blog_bottom_content">
                          <h5>Date:</h5>
                          <p>20 Dec, 2021</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="blog_bottom_boxed blog_left_padding">
                        <div className="blog_bottom_icon">
                          <img src="assets/img/icon/user.png" alt="icon" />
                        </div>
                        <div className="blog_bottom_content">
                          <h5>By:</h5>
                          <p>Admin</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="blog_two_wrapper mt-30">
              <a href="news-details.html">
                <img src={BlogImg} alt="img" /></a>
              <div className="news_two_text">
                <a href="news-details.html">#Work</a>
                <h3><a href="news-details.html">“Work for food” programme for
                  the elder people</a></h3>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog_two_wrapper">
              <a href="news-details.html">
                <img src={BlogImg} alt="img" /></a>
              <div className="news_two_text">
                <a href="news-details.html">#WomenRights</a>
                <h3><a href="news-details.html">Stop early marriage and educate your girl child</a></h3>
              </div>
            </div>
            <div className="blog_card_wrapper mt-30">
              <div className="blog_card_img">
                <a href="news-details.html">
                  <img src={BlogImg} alt="img" />
                </a>
              </div>
              <div className="blog_card_text">
                <div className="blog_card_tags">
                  <a href="news.html">#Habitation</a>
                </div>
                <div className="blog_card_heading">
                  <h3><a href="news-details.html">Arrange rehabitation facility for the homeless
                    people</a></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod...</p>
                </div>
                <div className="blog_boxed_bottom_wrapper ">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="blog_bottom_boxed">
                        <div className="blog_bottom_icon">
                          <img src="assets/img/icon/cal.png" alt="icon" />
                        </div>
                        <div className="blog_bottom_content">
                          <h5>Date:</h5>
                          <p>20 Dec, 2021</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="blog_bottom_boxed blog_left_padding">
                        <div className="blog_bottom_icon">
                          <img src="assets/img/icon/user.png" alt="icon" />
                        </div>
                        <div className="blog_bottom_content">
                          <h5>By:</h5>
                          <p>Admin</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="blog_two_wrapper mt-30">
              <a href="news-details.html">
                <img src={BlogImg} alt="img" />
              </a>
              <div className="news_two_text">
                <a href="news-details.html">#Education</a>
                <h3><a href="news-details.html">Give children a good education
                  & better life</a></h3>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog_card_wrapper">
              <div className="blog_card_img">
                <a href="news-details.html">
                  <img src={BlogImg} alt="img " />
                </a>
              </div>
              <div className="blog_card_text">
                <div className="blog_card_tags">
                  <a href="news.html">#Water</a>
                </div>
                <div className="blog_card_heading">
                  <h3><a href="news-details.html">Ensure pure and mineral drinking
                    water for rural people</a></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur notted adipisicing elit sed do eiusmod...</p>
                </div>
                <div className="blog_boxed_bottom_wrapper">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="blog_bottom_boxed">
                        <div className="blog_bottom_icon">
                          <img src="assets/img/icon/cal.png" alt="icon" />
                        </div>
                        <div className="blog_bottom_content">
                          <h5>Date:</h5>
                          <p>20 Dec, 2021</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="blog_bottom_boxed blog_left_padding">
                        <div className="blog_bottom_icon">
                          <img src="assets/img/icon/user.png" alt="icon" />
                        </div>
                        <div className="blog_bottom_content">
                          <h5>By:</h5>
                          <p>Admin</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="blog_two_wrapper mt-30">
              <a href="news-details.html">
                <img src={BlogImg} alt="img" />

              </a>
              <div className="news_two_text">
                <a href="news-details.html">#Cloths</a>
                <h3><a href="news-details.html">Give 10,000+ cloths to the street
                  children</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}

export default NewsSection