import React from 'react'
import Image1 from "../../assets/img/headerImage.png"
import { useDispatch, useSelector } from 'react-redux'
import { sendProjectData } from '../../redux/project/projectSlice'
import { useTranslation } from 'react-i18next'
import SliderHeader from './SliderHeader'

// headerImage.jpg 619 X 684
const Header = () => {

  const { t } = useTranslation();

  // const dispatch = useDispatch()
  // const  {projectId}  = useSelector(state => state.project);
  // const sendProject = (id) => {
  //   // console.log(id)
  //   dispatch(sendProjectData(id))
  //   console.log(projectId)
  // }


  return (
    <section id="home_one_banner">
      {/* <div className="container"> */}
        {/* <div className="row align-items-center justify-content-between"> */}
          <SliderHeader />
          {/* <div className="col-lg-6">
            <div className="banner_one_text">
              <h1>
                {t("header_main")}
              </h1>

              <p>{t("header_main_p")}</p>

            </div>
          </div>
          <div className="col-lg-5">
            <div className="banner_one_img">
              <img src={Image1} alt="img" />

              <div className="banner_element">
                <div className='element_2 shape-2 bg-shap-header2'>
                  <span className='bg-shap-header-text'>{t("header_main_num_orphan")}</span>
                </div>
                <div className='element_3 shape-3 bg-shap-header'>
                  <span className='bg-shap-header-text'>{t("header_main_num_person")}</span>
                </div>
              </div>
            </div>
          </div> */}
        {/* </div> */}
      {/* </div> */}
    </section>
  )
}
{/* <img src={Image2} alt="icon" className="element_1 shape-1" /> */ }
{/* <img src={Image3} alt="icon" className="element_3 shape-3" /> */ }

export default Header