import axios from 'axios'
import { t } from 'i18next';
import Moment, { format } from 'moment';
import { Buffer } from 'buffer';



class FileDownloaderHelperUtil {


    downloadDocument = (response, fileName) => {
        //var str2 = decodeURIComponent(escape(window.atob(b64)));
        let binaryString = (window.atob(response.data));

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "application/pdf" });

        //   let blob = new Blob([response.data],   {type: 'application/pdf'});

        let url = window.URL.createObjectURL(blob);
        // Window.open(url);
        const link = document.createElement('a');
        link.href = url;
        // link.setAttribute('download', ".file.pdf"); //or any other extension
        link.download = fileName + '.pdf';
        document.body.appendChild(link);
        link.click();
    }

    convertToImage = (imageByte) => {
        let binaryString = window.atob(imageByte);
        //let binaryString = window.(imageByte);

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }

        let blob = new Blob([bytes], { type: "image/*" });


        // myImage.src = URL.createObjectURL(blob);


        //   let blob = new Blob([response.data],   {type: 'application/pdf'});

        let url = window.URL.createObjectURL(blob);

        return url;
        // Window.open(url);
        // const link = document.createElement('a');
        // link.href = url;
        // // link.setAttribute('download', ".file.pdf"); //or any other extension
        // link.download = fileName + '.pdf';
        // document.body.appendChild(link);
        // link.click();       
    }


    // OK
    convertToDocumentNew = (base64Document) => {
        const base64String = base64Document || "";
        const buffer = Buffer.from(base64String, 'base64');

        let blob = (new Blob([buffer], { type: "application/pdf" }));

        let url = window.URL.createObjectURL(blob);
        // Window.open(url);
        const link = document.createElement('a');
        link.href = url;
        // link.setAttribute('download', ".file.pdf"); //or any other extension
        link.download = "fileName" + '.pdf';
        document.body.appendChild(link);
        link.click();

    }


    mohammedFunction = (res) => {

        // Insert a link that allows the user to download the PDF file
        var link = document.createElement('a');
        link.innerHTML = 'Download PDF file';
        link.download = 'file.pdf';
        link.href = 'data:application/octet-stream;base64,' + res;
        //link.href = 'data:application/pdf;base64,' + res; 
        document.body.appendChild(link);
        link.click();

        // console.log(link)
    }



}

export default new FileDownloaderHelperUtil();