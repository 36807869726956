import React, { useMemo, useState } from "react";
import { Route, Routes, Outlet, useLocation, useNavigate } from 'react-router-dom'
import MainContent from '../../components/dashboard/MainContent'
import NavDashboard from '../../components/dashboard/NavDashboard'
import Sidebar from '../../components/dashboard/Sidebar'
import TableAllUsers from "../../components/dashboard/TableAllUsers";
import UserPage from "./UserPage";
import Roles from "./Roles";
import Profile from "./Profile";
import "../../styles/Dashboard.css"


import { Box, createTheme, useMediaQuery, CssBaseline, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { themeSettings } from "../../theme";
import { useEffect } from "react";
import { CheckIsAuth } from "../../components/dashboard/CheackIsAuth";
import cookies from 'js-cookie'
import { ToastContainer } from "react-toastify";

const Dashboard = () => {

  const isMobile = useMediaQuery("(max-width: 992px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  // const userId = useSelector((state) => state.global.userId);

  const path = useLocation()


  const [mode, setmode] = useState(false);
  let modeDashboard = localStorage.getItem("mode") || "light"


  const theme = useMemo(() => createTheme(themeSettings(modeDashboard)), [modeDashboard]);

  let lang = cookies.get('i18next') === "ar"

  return (
    <div className='d' style={{ height: '100vh' }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Sidebar isMobile={isMobile}
          drawerWidth="250px"
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen} />

        <NavDashboard isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          setmode={setmode}
          mode={mode}
        />

        <Routes>
          <Route path="/" element={<MainContent isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />} />

          <Route path="/all" element={<TableAllUsers isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />} />

          <Route path="/:userId" element={<UserPage isSidebarOpen={isSidebarOpen} />} />

          <Route path="/getAll" element={<Roles isSidebarOpen={isSidebarOpen} />} />

          <Route path="/me" element={<Profile isSidebarOpen={isSidebarOpen} />} />

        </Routes>


  {/* toast.info(…)
    toast.error(…)
    toast.success(…)
    toast.warning(…)
    toast.dark(…)
    top-left
    top-right
    top-center
    bottom-left
    bottom-right
    bottom-center */}

        {
          lang ? <ToastContainer rtl /> : <ToastContainer />
        }

      </ThemeProvider>
    </div>
  )
}

export default Dashboard