import React from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import "../styles/Footer.css";
import FooterLogo from "../assets/img/logo-footer.png"
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'
{/* <InstagramIcon /> <TwitterIcon /> <FacebookIcon /> <LinkedInIcon /> */ }

function Footer() {
  const { t } = useTranslation();

  const location = useLocation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  const recipientEmail = "info@dehoop-pl.be";
  const subject = "Hello from React test test test!";
  const body = "This is the body of the email.";

  const phoneNumber = "+0032-24243650";
  const mobileNumber = "+0032496242429";

  function sendEmail() {
    window.location.href = `mailto:${recipientEmail}?subject=${subject}&body=${body}`;
  }

  function callPhoneNumber() {
    if (window.innerWidth < 550) {
      window.location.href = `tel:${phoneNumber}`;
    }
  }

  function callMobileNumber() {
    if (window.innerWidth < 550) {
      window.location.href = `tel:${mobileNumber}`;
    }
  }

  return (
    !location.pathname.startsWith('/admin') ? (
      <footer id="footer_area">
        <div className="container pb-5">
          <div className="row pt-5">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <Link to="/" className="footer_area_about " onClick={() => scrollToTop()} >
                <img src={FooterLogo} className='logoFooter' alt="img" width="160" height="160" />
                {/* <p>{t("footer_description")}</p> */}
              </Link>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="footer_navitem_ara">
                <div className="nav_item_footer mt-4">
                  <h6 className="mb-4"><strong>{t("footer_label_address")}: </strong>
                    {t("footer_address")}
                  </h6>
                  <h6 className="mb-4"><strong>{t("footer_label_phone")}: </strong>
                    <span className="cursor-pointer" onClick={callPhoneNumber}>
                      {t("footer_tel")}
                    </span>
                  </h6>
                  <h6 className="mb-4"><strong>{t("footer_label_mobile_tel")}: </strong>
                    <span className="cursor-pointer" onClick={callMobileNumber}>
                      {t("footer_phone")}
                    </span>
                  </h6>
                  {/* <h6 className="mb-4"><strong>{t("footer_label_email")}:</strong> {t("footer_email")}</h6> */}
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="footer_navitem_ara">
                <h3>{t("footer_quick_links")}</h3>
                <div className="nav_item_footer">
                  <ul>
                    <li><Link to="/whoWeAre">{t("footer_link_whoWeAre")}</Link></li>
                    <li><Link to="/publication">{t("footer_link_Publication")}</Link></li>
                    <li><Link to="/gallery/1">{t("footer_link_gallery")}</Link></li>
                    <li><Link to="/volunteerWithUs">{t("footer_link_volunteerWithUs")}</Link></li>
                    <li><Link to="/contact">{t("footer_link_contact")}</Link></li>
                  </ul>
                </div>
              </div>
            </div>


          </div>
        </div>
        {/* copy right */}
        <div className="copyright_area">
          <div className="container">
            <div className="row align-items-center text-center">
              <div className="co-lg-6 col-md-6 col-sm-12 col-12">
                <div className="copyright_left">
                  <p>{t('footer_copyRight')}</p>
                </div>
              </div>
              <div className="co-lg-6 col-md-6 col-sm-12 col-12">
                <div className="copyright_right">
                  <ul>
                    <li className="icon-footer">
                      <a href="https://www.facebook.com/dehoopson" target="_blank">
                        <FacebookIcon />
                      </a>
                    </li>
                    <li className="icon-footer">
                      <a href="https://www.twitter.com/dehoopson" target="_blank">
                        <TwitterIcon />
                      </a>
                    </li>
                    <li className="icon-footer">
                      <a href="https://www.instagram.com/dehoopson" target="_blank">
                        <InstagramIcon />
                      </a>
                    </li>
                    {/* <li>
                      <a href="#!">
                        <LinkedInIcon />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>) : null
  );
}

export default Footer;
