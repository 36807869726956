import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";

export default function CheckoutForm({ showModal, setShowModal, donateValue }) {

  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation()

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    localStorage.setItem("donateValue", donateValue)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completeDonate`,
      },
    })

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }
    setIsProcessing(false);
  };

  const handleClose = () => {
    setShowModal(false)
  };

  return (
    <div>

      <h4 className="pt-2 pb-4">{t("volunteer_with_us_donation_you_will_donate")} {donateValue} Є</h4>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />

        <div className="d-flex justify-content-center align-items-center">
          <Button variant="secondary" style={{ margin: "40px 10px 10px" }} onClick={handleClose}>
            {t('crud_general_cancel')}
          </Button>

          <Button variant="primary" style={{ margin: "40px 10px 10px" }} type='submit' disabled={isProcessing || !stripe || !elements} id="submit" >
            <span id="button-text">
              {isProcessing ? t("volunteer_with_us_donation_processing") : t("volunteer_with_us_donation_pay_now")}
            </span>
          </Button>
        </div>

        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>

    </div>
  );
}