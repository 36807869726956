import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { apiClient } from '../../services/auth/ApiClient'
import { authService } from '../../services/auth/AuthService'
import { BsPerson } from "react-icons/bs"
import { CgPassword } from "react-icons/cg"
import ResetPasswordAdmin from './ResetPasswordAdmin'
import { notify } from '../../components/Toast'

const LoginAdmin = () => {


  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [flag, setFlag] = useState(true)
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault()

    apiClient.signIn({ username, password })
      .then(res => {
        if (res.access_token) {
          //login success
          // localStorage.setItem("username", )
          console.log(res.user, res.access_token, res.refresh_token);
          authService.login(res.user, res.access_token, res.refresh_token);
          notify({ info: "User Login successfully", status: "success" });

          navigate("/admin"); //redirect to home page after success login
        } else {
          //login failure
          notify({ info: "Login failure", status: "failed" });

          // alert("Login failure");
          authService.logout();
        }
      });
  }

  const [email, setEmail] = useState("")

  const handleSubmitResetPassword = (event) => {

    event.preventDefault();

    apiClient.resetPassword(email)
      .then(res => {
        if (res?.message) {
          //reset password request success
          notify({ info: res.message, status: "success" });

          // alert(res.message);
          // history.push("/login"); //redirect to login page after success
        }
      });
  }

  return (
    <div className='body-form'>
      {/* <div className="tab-content">
        <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login"> */}
      {/* <form onSubmit={handleSubmit}>
            <div className="input-group flex-nowrap mb-4">
              <input type="text" className="form-control" placeholder="ادخل اسمك" required onChange={(e) => setUsername(e.target.value)} />
            </div>

            <div className="input-group flex-nowrap mb-4">
              <input type="text" className="form-control" placeholder="ادخل كلمة المرور" required onChange={(e) => setPassword(e.target.value)} />
            </div>

            <div className="mb-3 form-check">
              <Link to="/reset-password">هل نسيت كلمة المرور?</Link>
            </div>

            <button type="submit" className="btn btn-primary btn-block mb-4 w-100">سجل دخولك من هنا</button>

          </form> */}

      {
        flag ?
          <form className='login-form1' onSubmit={handleSubmit}>
            <h3 className='mb-5 text-light text-center'>Admin Login Dashboard</h3>
            <div className="flex-row">
              <label className="lf--label" htmlFor="username">
                <BsPerson />
              </label>
              <input id="username" className='lf--input' placeholder='Username' required onChange={(e) => setUsername(e.target.value)} type='text' />
            </div>
            <div className="flex-row">
              <label className="lf--label" htmlFor="password">
                <CgPassword />
              </label>
              <input id="password" className='lf--input' placeholder='Password' required onChange={(e) => setPassword(e.target.value)} type='password' />
            </div>

            <input className='lf--submit' type='submit' value='LOGIN' />

            {/* <Link to="/admin/reset-password" className='h6 mt-4 mb-0 text-black'>If you forget the password!</Link> */}
            <p className=' mt-4 mb-0 text-black' onClick={() => setFlag(prev => !prev)} style={{ fontSize: "14px" }}>
              If you forget the password!
            </p>
            <Link to="/" className='h6'>Back to Home Page</Link>

          </form>

          :

          <ResetPasswordAdmin flag={flag} setFlag={setFlag} />
      }


      {/* </div>

      </div> */}
    </div>
  )
}

export default LoginAdmin