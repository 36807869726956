import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { apiClient } from '../services/auth/ApiClient';
import { authService } from '../services/auth/AuthService';
import { async } from 'rxjs';
import { notify } from '../components/Toast';

const RegisterPage = () => {
  const { t } = useTranslation();


  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const navigate = useNavigate();
  const location = useLocation()

  // let registerData = {
  //     username: event.target.username.value,
  //     password: event.target.password.value,
  //     firstname: event.target.firstname.value,
  //     lastname: event.target.lastname.value,
  //     email: event.target.email.value,
  //     city: event.target.city.value,
  //     address: event.target.address.value
  // };


  const [info, setInfo] = useState({ username: "", firstname: "", lastname: "", password: "", email: "", address: "", city: "" })


  const onlySpaces = (str) => str.trim().length > 8

  const handleSubmit = async (event) => {
    // Prevent default behavior
    event.preventDefault();
    console.log(info)
    
    let registerData = {
        username: info.username,
        password: info.password,
        firstname: info.firstname,
        lastname: info.lastname,
        email: info.email,
        city: info.city,
        address: info.address
    };

    // let formData = new FormData(event.target);

    // let registerData = {};
    // formData.forEach((value, key) => registerData[key] = value);

    let response = await apiClient.signOn(registerData);
    const status = response.status;
    const body = await response.json();

    if (status === 200) {
      notify({ info: body.message, status: "success" });

    // alert(body.message);
    navigate("/"); //redirect to home page after success register
    } else {
      notify({ info: `Register failure: ${JSON.stringify(body)}`, status: "failed" });
      // alert("Register failure: " + JSON.stringify(body));

      authService.logout();
    }
  }


  return (
    <div className="login-form">
      <ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
        <li className="nav-item" role="presentation">
          <Link to="/login" className="nav-link " >{t("register_page_login_text")}</Link>
        </li>
        <li className="nav-item">
          <Link to="/register" className="nav-link active">{t("register_page_register_text")}</Link>
        </li>
      </ul>
      <div className="tab-content">
        <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
          <form onSubmit={handleSubmit}>

            <div className="input-group flex-nowrap mb-4">
              <input type="text" className="form-control" value={info.firstname} onChange={e => setInfo({ ...info, firstname: e.target.value })} placeholder={t("register_page_enter_fname")} />
            </div> 
            <div className="input-group flex-nowrap mb-4">
              <input type="text" className="form-control" value={info.lastname} onChange={e => setInfo({ ...info, lastname: e.target.value })} placeholder={t("register_page_enter_lname")} />
            </div>

            <div className="input-group flex-nowrap mb-4">
              <input type="text" className="form-control" value={info.username} onChange={e => setInfo({ ...info, username: e.target.value })} placeholder={t("register_page_enter_username")} />
            </div>

            <div className="input-group flex-nowrap mb-4">
              <input type="text" className="form-control" value={info.email} onChange={e => setInfo({ ...info, email: e.target.value })} placeholder={t("register_page_enter_email")} />
            </div>

            <div className="input-group flex-nowrap mb-4">
              <input type="text" className="form-control" value={info.city} onChange={e => setInfo({ ...info, city: e.target.value })} placeholder={t("register_page_enter_city")} />
            </div>
            <div className="input-group flex-nowrap mb-4">
              <input type="text" className="form-control" value={info.address} onChange={e => setInfo({ ...info, address: e.target.value })} placeholder={t("register_page_enter_address")} />
            </div>

            <div className="input-group flex-nowrap mb-4">
              <input type="password" className="form-control" value={info.password} onChange={e => setInfo({ ...info, password: e.target.value })} placeholder={t("register_page_enter_password")} />
            </div>

            <button type="submit" className="btn btn-primary btn-block mb-4 w-100">{t("register_page_register_new")}</button>

          </form>
        </div>

      </div>
    </div>
  )
}

export default RegisterPage