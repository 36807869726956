import React, { useState } from "react"
import { Link } from "react-router-dom";

import { BsFillInfoCircleFill, BsPencilFill } from "react-icons/bs"
import { BsShieldShaded } from "react-icons/bs"

import { authService } from "../../../services/auth/AuthService"

import { ProfileImage } from "../../../components/dashboard/users/ProfileImage"

const User1Info = (props) => {

  const user = props.user;
  // console.log(user)
  const [isShowAuthorities, setShowAuthorities] = useState(props.isShowAuthorities);
  const [isShowDetails, setShowDetails] = useState(props.isShowDetails);

  return (
    <div >
      {user &&
        <div className='car d'>
          <div className='card-header'>
            <div className='row'>
              <div className='col-1'>
                <ProfileImage user={user} width='40' height='40' />
              </div>
              <div className='col-5'>
                <h3>{user.username}</h3>
              </div>
              <div className='col-1 offset-3'>
                <button className='border-0 bg-transparent' onClick={() => setShowDetails(!isShowDetails)}>
                  <BsFillInfoCircleFill className="h4 text-light" />
                </button>
              </div>
              <div className='col-1'>
                <button className='border-0 bg-transparent' onClick={() => setShowAuthorities(!isShowAuthorities)}>
                  <BsShieldShaded className="h4 text-light" />
                </button>
              </div>
              {authService.userHasAuthority('user.update') &&
                <div className='col-1'>
                  <Link to={`/admin/user/${user.id}`} className='nav-link  bg-transparent'>
                    <BsPencilFill className="h5 text-light" />
                  </Link>
                </div>
              }
            </div>
          </div>
          <div className='card-body px-4'>
            <div className='row'>
              <div className='col-sm-2'>
                {user.firstname}
              </div>
              <div className='col-sm-2'>
                {user.lastname}
              </div>
              <div className='col-sm-6'>
                {user.email}
              </div>
            </div>
            <div className='row my-3 mb-5' hidden={!isShowDetails}>
              <div className='col-sm-4 border border-primary py-2'>
                {user.city}
              </div>
              <div className='col-sm-8 border border-primary py-2'>
                {user.address}
              </div>
            </div>
            <div className='row mt-2' hidden={!isShowAuthorities}>
              <div className='col-md-2 col-sm-4'>
                <h5 className="mb-2">Roles</h5>
                {user.roles?.map(role => (
                  <div className='row border border-danger py-2 px-2' key={role}>
                    {role}
                  </div>
                ))}
              </div>
              <div className='col-md-10 col-sm-8' >
                <div className='row'>
                  <h5 className="mb-2">Authorities</h5>
                  {user.authorities?.map(authority => (
                    <div className='col-sm-6 col-md-4 col-xl-3  border border-secondary py-2' key={authority}>
                      {authority}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default User1Info;