import React from 'react'
import TableUsersB from './users/TableUsersB'

const TableAllUsers = ({ isSidebarOpen, setIsSidebarOpen }) => {
  return (
    <div className={isSidebarOpen ? " maincontent " : " maincontent mx-3 mx-lg-5 "}>
      <h3 className='mb-4'>Users: </h3>
      {/* <DataTable /> */}
      <TableUsersB />
    </div>
  )
}

export default TableAllUsers