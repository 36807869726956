import React, { useEffect, useState } from 'react'
import "../styles/Navbar.css";
import Logo from "../assets/img/logo.png"

import { IoMdMail } from "react-icons/io";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaBars } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import DropdownLang from './DropdownLang';


import { useTranslation, Trans } from 'react-i18next';
import { useQuery } from 'react-query';
import { getTypeProjects } from '../services/api/user';
import { useDispatch, useSelector } from 'react-redux';
import { getIdTypeProject } from '../redux/project/projectSlice';
import { authService } from '../services/auth/AuthService';
import CharityProjectTypeService from '../services/CharityProjectTypeService';

// import {t} from 'i18next';

const Navbar = () => {

  const { t } = useTranslation();

  const [flag, setFlag] = React.useState(false);

  const [projectsTypes, setProjectsTypes] = useState()

  useEffect(() => {
    CharityProjectTypeService.getProjectTypesWithSpecificLang()
      .then((response) => {
        setProjectsTypes(response.data)
      }).catch(error => {
        console.log(error);
      })
  }, [])

  const refresh = () => window.location.reload(true)

  const chooseTypeProject = (id) => {
    window.history.pushState(null, null, `/gallery/${id}`);
    refresh()
  }


  const location = useLocation();
  const [url, setUrl] = useState(location.pathname)
  const [showList, setShowList] = useState(false)

  const navigate = useNavigate();
  function handleLogout() {
    authService.logout();
    navigate('/login')
  }

  const recipientEmail = "info@dehoop-pl.be";
  const subject = "Hello from React test test test!";
  const body = "This is the body of the email.";

  const phoneNumber = "+0032-24243650";

  function sendEmail() {
    window.location.href = `mailto:${recipientEmail}?subject=${subject}&body=${body}`;
  }

  function callPhoneNumber() {
    if (window.innerWidth < 550) {
      window.location.href = `tel:${phoneNumber}`;
    }
  }


  useEffect(() => {
    setFlag(false);
  }, [location]);

  return (
    !location.pathname.startsWith('/admin') ? (
      <header className="main_header_arae">
        <div className="topbar-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <ul className="topbar-list">
                  <li onClick={() => sendEmail()}>
                    <IoMdMail className='m' />
                    <span>info@dehoop-pl.be</span>
                  </li>
                  <li onClick={() => callPhoneNumber()}>
                    <BsFillTelephoneFill className='m' />
                    <span>{t("number_top_header")}</span>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
        <div className="navbar-area">
          {/* For Mobile */}
          <div className="main-responsive-nav">
            <div className="container">
              <div className="main-responsive-menu">
                <div className="d-flex justify-content-between align-items-center">
                  <Link to="/">
                    <img src={Logo} alt="logo" className='logo-header' />
                  </Link>

                  <div className={` ${flag ? "no-collapse" : "collapse"}`} id="">
                    <DropdownLang />
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link to="/" className="nav-link active">{t('nav_home')}</Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/whoWeAre" className="nav-link">
                          {t('nav_about_us')}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/publication" className="nav-link">
                          {t('nav_publications')}
                        </Link>
                      </li>
                      <li className="nav-item" onClick={() => setShowList(prev => !prev)}>
                        <button className="nav-link cursor_pointer_moh" style={{ all: 'unset', color: '#0a58ca' }} >
                          {t("nav_gallery")}
                          <IoIosArrowDown />
                        </button>
                        {/* <Link to="" className="nav-link cursor_pointer_moh" onClick={() => setShowList(prev => !prev)}>
                          {t("nav_gallery")}
                          <IoIosArrowDown />
                        </Link> */}
                        <ul className={`dropdown-menu ${showList ? "show" : ""}`}>
                          {projectsTypes && projectsTypes.map(el =>
                            <li className="nav-item" key={el.content[0].id} >
                              <Link to={`/gallery/${el.id}`} className="nav-link" >
                                {el.content[0].projectType}
                              </Link>
                            </li>
                          )}
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link to="/supportUs" className="nav-link">
                          {t("nav_support_us")}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/contact" className="nav-link">
                          {t("nav_contact_us")}
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        {
                          authService.currentUserValue ?
                            <div className="dropdown py-2">
                              <button className="btn btn-secondary dropdown-toggle px-3 d-flex justify-content-between align-items-center" style={{ background: "#1b77fd" }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {JSON.parse(localStorage.getItem('currentUser')).username}
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                  <Link className="dropdown-item nav-link" to={`/profileMe/${JSON.parse(localStorage.getItem('currentUser')).id}`}>{t("nav_Profile_word")}</Link>
                                </li>
                                <li>
                                  <Link to='#' className='dropdown-item nav-link' onClick={handleLogout}>
                                    {t("nav_logout")}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            :
                            <Link to="/login" className="nav-link">
                              {t("nav_login")}
                            </Link>
                        }
                      </li> */}
                    </ul>
                  </div>

                  <button onClick={() => setFlag(prev => !prev)} className='' style={{ border: "0", background: "#fff", cursor: "pointer" }} >
                    <FaBars className='w-100 h3' />
                  </button>
                </div>
              </div>
            </div>
          </div>


          <div className="main-navbar">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light">
                <Link to="/" className="navbar-brand" >
                  <img src={Logo} alt="logo" className='logo-header' />
                </Link>
                <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                  <ul className="navbar-nav mx-5">
                    <li className="nav-item">
                      <Link to="/" className="nav-link active">{t('nav_home')}</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/whoWeAre" className="nav-link">
                        {t('nav_about_us')}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/publication" className="nav-link">
                        {t('nav_publications')}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="" style={{ pointerEvents: "none" }} className="nav-link cursor_pointer_moh" onClick={() => setShowList(prev => !prev)}>
                        {t('nav_gallery')}
                        <IoIosArrowDown />
                      </Link>

                      <ul className="dropdown-menu">
                        {projectsTypes && projectsTypes.map(el =>
                          <li className="nav-item cursor_pointer_moh" key={el.content[0].id} onClick={() => chooseTypeProject(el.id)}>
                            <button className="nav-link" style={{ all: 'unset' }}>
                              {el.content[0].projectType}
                            </button>
                            {/* <Link to={`/gallery/${el.id}`} className="nav-link">
                            {el.content[0].projectType}
                            </Link> */}
                          </li>
                        )}
                      </ul>

                    </li>
                    {/* <li className="nav-item">
                    <Link to="/volunteerWithUs" className="nav-link">
                      {t('nav_volunteer_with_us')}
                    </Link>
                  </li> */}
                    <li className="nav-item">
                      <Link to="/supportUs" className="nav-link">
                        {t('nav_support_us')}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/contact" className="nav-link">
                        {t('nav_contact_us')}
                      </Link>
                    </li>
                  </ul>
                  {/* authService.userHasAuthority('ROLE_USER') */}
                  {/* {
                    authService.currentUserValue ?
                      <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle px-3 d-flex justify-content-between align-items-center" style={{ background: "#1b77fd" }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          {JSON.parse(localStorage.getItem('currentUser')).username}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                          <li>
                            <Link className="dropdown-item" to={`/profileMe/${JSON.parse(localStorage.getItem('currentUser')).id}`}>Profile</Link>
                          </li>
                          <li>
                            <Link to='#' className='nav-link' onClick={handleLogout}>
                              {t("nav_logout")}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      :
                      <Link to="/login" className="nav-link">
                        {t("nav_login")}
                      </Link>
                  } */}

                </div>
                <DropdownLang />
              </nav>
            </div>
          </div>
        </div >
      </header >) : null
  )
}

export default Navbar

