import React, { useState } from "react";
// import PizzaLeft from "../assets/pizzaLeft.jpg";
import "../styles/Contact.css";
import { useTranslation } from 'react-i18next'
import { BsFillTelephoneFill } from "react-icons/bs";
import EmailService from "../services/EmailService";
import { notify } from "../components/Toast";

function Contact() {
  const { t } = useTranslation();
  const [ senderMobileNumber, setSenderMobileNumber ] = useState("")
  const [ senderEmail, setSenderEmail ] = useState("")
  const [ senderMessage, setSenderMessage ] = useState("")
  const [ senderName, setSenderName ] = useState("")

  // const emailRequest = { senderMobileNumber: "45453", senderEmail : "a@a.com", senderMessage: "I love charity", senderName: "X man" }
  const emailRequest = { senderMobileNumber, senderEmail, senderMessage, senderName }
  console.log("🚀 ~ file: Contact.js:17 ~ Contact ~ emailRequest:", emailRequest)

  const handleSubmit = (e) => {
    e.preventDefault();
    EmailService.contactUsSendEmail(emailRequest)
      .then((response) => {
        // console.log(response)
        notify({ info: "You Send The Message successfully", status: "success" });
        setSenderMobileNumber("")
        setSenderEmail("")
        setSenderMessage("")
        setSenderName("")
      }).catch(error => {
        console.log(error);
      })
  }


  return (
    <div className="contact contact-d etails section -block">
      <div className="container d-flex flex-column flex-lg-row">

        <div className="leftSide">
          <h2 className="mb-1">{t("contact_us")}</h2>
          <div>
            <h5 className="mb-4"><strong>{t("footer_label_address")}:</strong> {t("footer_address")}</h5>
            <h5 className="mb-4"><strong>{t("footer_label_phone")}:</strong> {t("footer_phone")}</h5>
            <h5 className="mb-4"><strong>{t("footer_label_mobile_tel")}:</strong> {t("footer_tel")}</h5>
          </div>
          <span></span>
          <span></span>
        </div>

        <div className="rightSide">
          <form id="contact-form" onSubmit={handleSubmit} >
            <label htmlFor="name">{t("contact_us_fullname")}</label>
            <input name="name" value={senderName} onChange={e => setSenderName(e.target.value)} placeholder={t("contact_us_fullname_placeholder")} type="text" required />

            <label htmlFor="email">{t("contact_us_email")}</label>
            <input name="email" value={senderEmail} onChange={e => setSenderEmail(e.target.value)} placeholder={t("contact_us_email_placeholder")} type="email" required />

            <label htmlFor="phone">{t("contact_us_phone")}</label>
            <input name="phone" value={senderMobileNumber} onChange={e => setSenderMobileNumber(e.target.value)} placeholder={t("contact_us_phone_placeholder")} type="text" />

            <label htmlFor="message">{t("contact_us_message")}</label>
            <textarea
              rows="6"
              placeholder={t("contact_us_message_placeholder")}
              name="message"
              required
              value={senderMessage} onChange={e => setSenderMessage(e.target.value)}
            ></textarea>
            <button type="submit" className="submit-btn btn-primary">{t("contact_sendIt")}</button>
          </form>
        </div>

        {/* <div className="section-title v1">
          <h3>Our Location</h3>
        </div>

        <div className="boxes cols-3">
          <div className="box">
            <h3>Address</h3>
            <div className="content"><p> Rue Vandroogenbroeck 4,<br />
              1030 Schaerbeek<br />
              VAT : BE0476.082.928</p>
            </div>
            <span className="icon " aria-hidden="true">
              <BsFillTelephoneFill />
            </span>
          </div>
          <div className="box">
            <h3>Phone</h3>
            <div className="content"><p>+32 494 48 01 30</p>
            </div>
            <span className="icon fas fa-phone" aria-hidden="true"></span>
          </div>
          <div className="box">
            <h3>Opening Hours</h3>
            <div className="content"><p>Monday -Friday 17H00-22H00<br />
              Saturday 17H00 – 23H00<br />
              Sunday  Closed</p>
            </div>
            <span className="icon fas fa-clock" aria-hidden="true"></span>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Contact;
