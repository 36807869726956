import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CheckIsAuth } from '../components/dashboard/CheackIsAuth'
import { apiClient } from "../services/auth/ApiClient"
import { authService } from '../services/auth/AuthService'
import { useTranslation } from 'react-i18next'
import { notify } from '../components/Toast'

const LoginPage = () => {
  const { t } = useTranslation();

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const navigate = useNavigate();
  const location = useLocation()

  const handleSubmit = (e) => {
    e.preventDefault()

    apiClient.signIn({ username, password })
      .then(res => {
        if (res.access_token) {
          //login success
          // localStorage.setItem("username", )
          console.log(res.user, res.access_token, res.refresh_token);
          let newUser = res.user;
          delete newUser.authorities
          authService.login(newUser, res.access_token, res.refresh_token);
          notify({ info: "User Login successfully", status: "success" });
          navigate("/"); //redirect to home page after success login
        } else {
          //login failure
          notify({ info: `there is error (${res.error})`, status: "failed" });
          // alert("Login failure");
          authService.logout();
        }
      });
  }

  const [loggedInUser, setLoggedInUser] = useState(authService.currentUserValue);
  const isAuthenticated = !!loggedInUser;

  useEffect(() => {
    authService.currentUser.subscribe(x => setLoggedInUser(x));

    if(location.pathname == "/login" && isAuthenticated) {
      navigate("/")
    }
  }, []);



  return (
    <div className='login-form'>
      <ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
        <li className="nav-item" role="presentation">
          <Link to="/login" className="nav-link active" >{t("login_page_login_text")}</Link>
        </li>
        <li className="nav-item">
          <Link to="/register" className="nav-link">{t("login_page_register_text")}</Link>
        </li>
      </ul>
      <div className="tab-content">
        <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
          <form onSubmit={handleSubmit}>
            <div className="input-group flex-nowrap mb-4">
              <input type="text" className="form-control" placeholder={t("login_page_enter_name")} required onChange={(e) => setUsername(e.target.value)} />
            </div>

            <div className="input-group flex-nowrap mb-4">
              <input type="password" className="form-control" placeholder={t("login_page_enter_password")} required onChange={(e) => setPassword(e.target.value)} />
            </div>

            <div className="mb-3 form-check">
              <Link to="/reset-password">{t("login_page_forget_password")}</Link>
            </div>

            <button type="submit" className="btn btn-primary btn-block mb-4 w-100">{t("login_page_loginfromhere")}</button>

            <div className="text-center">
              <p><Link to="/register">{t("login_page_register_fromHere")}</Link></p>
            </div>
          </form>
        </div>

      </div>
    </div>
  )
}

export default LoginPage