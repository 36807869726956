import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiClient } from "../services/auth/ApiClient";
import { useTranslation } from 'react-i18next'
import { notify } from "../components/Toast";

const ResetPassword = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();

  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")

  const [authEmail, setAuthEmail] = useState(false)

  const handleSubmit = (e) => {

    e.preventDefault();

    // const email = event.target.email.value;

    apiClient.resetPassword(email)
      .then(res => {
        if (res?.message) {
          //reset password request success
          notify({ info: res.message, status: "success" });

          // alert(res.message);
          setAuthEmail(true)
          // navigate('/login')
        }
      });
  }

  return (
    <div className="Auth-form-container py-5" dir="ltr">

      <div className="Auth-form py-5">
        <div className="form-box solid">
          <form className="Auth-form" onSubmit={handleSubmit}>
            <div className="Auth-form-content">
              <h3 className="text-center">{t("reset_password_text")}</h3>
              {
                authEmail ?
                  <div className="form-group mt-3">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="form-control mt-1"
                      placeholder={t("reset_password_email")}
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>

                  :
                  <div className="form-group mt-3">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      className="form-control mt-1"
                      placeholder={t("reset_password_your_new_password")}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
              }

              <Link className="mt-2 px-2 d-block" to="/login" >{t("reset_password_back_login")}</Link>

              <div className="d-grid gap-2 mt-1">
                <button type="submit" className="btn btn-primary w-100">
                  {t("reset_password_btn_reset")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default ResetPassword;