import axios from 'axios'
import Cookies from 'js-cookie';
import HelperUtil from './ServiceHelperUtil';

const PUBLICATION_BASE_REST_API_URL = HelperUtil.getBaseURL() + '/publication';
const currentLanguageCode = Cookies.get('i18next') || 'fr'
class PublicationService{

    /**
     * Get All publications
     * @returns  'active' and 'inactive' publications in all lang
     */
    getALLPublications(){
        return axios.get(PUBLICATION_BASE_REST_API_URL );
    }

    /**
     * Get publications with Specific lan
     * @param {*} lang 
     * @returns : 'active' and 'inactive' publications in specific lang
     */
    getPublicationsWithSpecificLang(){
        return axios.get(PUBLICATION_BASE_REST_API_URL + '/' + currentLanguageCode)
    }

    /**
     * Get Active publications with Specific lang
     * 
     * @param {*} lang 
     * @returns only active publications with specific lang
     */
    getActivePublicationsWithSpecificLang(){
        return axios.get(PUBLICATION_BASE_REST_API_URL + '/summary/active/' + currentLanguageCode)
    }

    /**
     * Get publication by id
     * @param {*} id 
     * @returns 
     */
    getPublicationById(id){
        return axios.post(PUBLICATION_BASE_REST_API_URL + '/id/' + id);
    }

    getPublicationByIdForDownload(id){
        return axios.get(PUBLICATION_BASE_REST_API_URL + '/publication-download/id/' + id);
    }

}

export default new PublicationService();