import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { apiClient } from '../../services/auth/ApiClient'
import { authService } from '../../services/auth/AuthService'
import { BsPerson } from "react-icons/bs"
import { CgPassword } from "react-icons/cg"
import { notify } from '../../components/Toast'

const ResetPasswordAdmin = ({ flag, setFlag }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("")

  const handleSubmitResetPassword = (event) => {

    event.preventDefault();

    apiClient.resetPassword(email)
      .then(res => {
        if (res?.message) {
          //reset password request success
          notify({ info: res.message, status: "success" });

          // alert(res.message);
          navigate("/admin/login"); //redirect to login page after success
        }
      });
  }


  return (
    <>
      {!flag ?
        <form className="login-form1" onSubmit={handleSubmitResetPassword}>
          <div className="text-light">
            <h3 className="text-center pb-3"> Reset password</h3>
            <div className="form-group mt-3 ">
              <input
                id="email"
                name="email"
                type="email"
                className="form-control mt-1 bg-light"
                placeholder="Enter email"
              />
            </div>

            <div className="d">
              <button type="submit" className="lf--submit mt-4 mb-2">
                Reset password
              </button>
            </div>
            <span className='me-4 h6 ' onClick={() => setFlag(prev => !prev)}>Back To Login</span>
          </div>
        </form>

        : ""

      }
    </>

  )
}

export default ResetPasswordAdmin