import React from 'react'
import HeaderDetails from '../components/causes/HeaderDetails'
import TrendingCausesDetails from '../components/causes/TrendingCausesDetails'

const CausesDetails = () => {
  return (
    <>
      <HeaderDetails />
      <TrendingCausesDetails />
    </>
  )
}

export default CausesDetails