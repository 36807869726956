import React, { useEffect, useState } from "react"
import { apiClient } from "../../services/auth/ApiClient";
import { useParams } from "react-router-dom";

import EditUserAssignRole from "../../components/dashboard/users/EditUserAssignRole";
import EditUserAssignSeparateAuthority from "../../components/dashboard/users/EditUserAssignSeparateAuthority";

import { authService } from "../../services/auth/AuthService";
import User1Info from "../../components/dashboard/users/User1Info";


const UserPage = ({ isSidebarOpen }) => {

  let { userId }  = useParams();
  const [user, setUser] = useState();
    
  // let userId = JSON.parse(localStorage.getItem('currentUser')).id

  useEffect(() => {
    apiClient.getUserById(userId)
      .then(fetchedUser => {
        setUser(fetchedUser);
      });
  }, []);


  return (
    <div className={`userPage ${isSidebarOpen ? "userPage-open" : "userPage-close mx-3 mx-lg-5"}`}>
      <User1Info user={user}
        isShowDetails={true}
        isShowAuthorities={true} />

      {/* authService.userHasAuthority('user.assignRoles') && */}
      {
        <EditUserAssignRole user={user} updateUser={setUser} />
      }
      {/* authService.userHasAuthority('user.assignAuthorities') && */}
      {
        <EditUserAssignSeparateAuthority user={user} updateUser={setUser} />
      }

    </div>
  )
}

export default UserPage;