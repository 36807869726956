import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Round from "../../assets/img/icon/round.png"


import CharityProjectTypeService from '../../services/CharityProjectTypeService';
import Convert64ToImage from '../../services/Convert64ToImage';
import Loader from '../Loader';



const ProjectTypesComponent = () => {
  const { t } = useTranslation();
  const [projectsTypes, setProjectsTypes] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const arrColors = ["bg_one", "bg_two", "bg_three", "bg_four"];

  useEffect(() => {
    CharityProjectTypeService.getProjectTypesWithSpecificLang().then((response) => {
      setProjectsTypes(response.data)
      setIsLoading(false)
      // console.log(response.data);
    }).catch(error => {
      setIsLoading(false)
      console.log(error);
    })
  }, [])
  if (isLoading) {
    return (
      <><Loader /></>
    )
  }

  return (
    <section id="about_top_area" className="section_padding">
      <div className="container">
        <h3 className='h1 text-center pb-5 mb-5'>{t("h2_Project_types")}</h3>
        <div className="row">
          {
            projectsTypes.map((el) =>
              <div key={el.id} className="col-xl-3 col-md-6 col-sm-12 col-12 mb-5">
                <div className={`about_top_boxed mb-5  ${arrColors[Math.floor(Math.random() * 4)]}`} >
                  <div className="about_top_boxed_icon">
                    <Convert64ToImage mainImage={el.icon} />
                  </div>
                  <div className="about_top_boxed_text">
                    <h3 >{el.content[0].projectType}</h3>
                    <h5>{el.content[0].shortDescription}</h5>
                    {/* <h5>More details...</h5> */}
                  </div>
                  <div className="about_top_boxed_vector">
                    <img src={Round} alt="img" />
                  </div>
                </div>
              </div>)
          }
        </div>
      </div>
    </section>




  )
}

export default ProjectTypesComponent
