import React, { useState } from 'react'
import { Buffer } from 'buffer';

const Convert64ToImage = ({ mainImage, fullWidth }) => {


  const base64String = mainImage || "";
  const buffer = Buffer.from(base64String, 'base64');


  const image = new Image();
  // image.src = `data:image/png;base64,${buffer.toString('base64')}`;

  image.src = URL.createObjectURL(new Blob([buffer], { type: 'image/*' }));



  return (
    <div className={`text-center ${fullWidth && "w-100"}`}>
      <img src={image.src}  alt="" id="img-64" />
    </div>
  )
}

export default Convert64ToImage