import React, { useEffect, useState } from 'react';
import "./App.css";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import About from "./pages/About";
import Contact from "./pages/Contact";

import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Login from "./components/Login";
import BtnScrollUp from './components/BtnScrollUp';
import Gallery from './pages/Gallery';
import Event from './pages/Event';
import EventDetails from './pages/EventDetails';
import Causes from './pages/Causes';
import CausesDetails from './pages/CausesDetails';
import News from './pages/News';
import SupportUs from './pages/SupportUs';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import VolunteerWithUs from './pages/VolunteerWithUs';
import ProjectDetails from './pages/ProjectDetailsPage';
import Publication from './pages/Publication';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';

import Dashboard from './pages/dashboard/Dashboard';
import Protected from './components/dashboard/Protected';
import ResetPassword from './pages/ResetPassword';
import NotFound from './pages/NotFound';
import LoginAdmin from './pages/dashboard/LoginAdmin';
import ResetPasswordAdmin from './pages/dashboard/ResetPasswordAdmin';
import { CheckIsAuth } from './components/dashboard/CheackIsAuth';
import ProfilePage from './pages/ProfilePage';
import { notify } from './components/Toast';
import { ToastContainer } from 'react-toastify';

import StripeIntegration from './components/payment/payment';
import Completion from './components/payment/completion';


const queryClient = new QueryClient()


function App() {

  const loggedIn = window.localStorage.getItem("LoggedIn");
  // alert("loggedIn");
  // alert(loggedIn);
  /*
  return (
    <div className="App">
      
    </div>
  );
  */


  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (!navigator.onLine)
        notify({ info: "There Is No Connection", status: "failed" });
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  // useEffect(() => {
  //   if (!navigator.onLine)
  //     notify({ info: "There Is No Connection", status: "failed" });
  //   // console.log()
  // }, [navigator.onLine])

  return (

    <QueryClientProvider client={queryClient}>
      <Router>
        <Navbar />
        <BtnScrollUp />

        <Routes>
          <Route path="/" element={<Home />} > </Route>
          <Route path="/whoWeAre" exact element={<About />} > </Route>
          <Route path="/volunteerWithUs" exact element={<VolunteerWithUs />} > </Route>
          <Route path="/projectDetails/:id" exact element={<ProjectDetails />} > </Route>
          <Route path="/publication" exact element={<Publication />} > </Route>
          <Route path="/supportUs" exact element={<SupportUs />} > </Route>
          <Route path="/payment" exact element={<StripeIntegration />} > </Route>
          <Route path="/completeDonate" element={<Completion />} />

          <Route path="/causes" exact element={<Causes />} > </Route>  {/* this page we don't use it  */}

          <Route path="/CausesDetails" exact element={<CausesDetails />} > </Route>  {/* this page we don't use it  */}

          <Route path="/event" exact element={<Event />} > </Route> {/* this page we don't use it  */}
          <Route path="/eventdetails" exact element={<EventDetails />} > </Route> {/* this page we don't use it  */}

          <Route path="/gallery/:id" exact element={<Gallery />} > </Route>

          <Route path="/news" exact element={<News />} > </Route>{/* this page we don't use it  */}

          <Route path="/contact" exact element={<Contact />} > </Route>

          {/* <Route path="/profileMe/:id" exact element={<ProfilePage />} > </Route> */}
          <Route path={`/profileMe/:id`} exact element={CheckIsAuth() ? <ProfilePage /> : <LoginPage />} > </Route>

          <Route path={`/login`} exact element={CheckIsAuth() ? <Home /> : <LoginPage />} > </Route>

          <Route path="/register" exact element={<RegisterPage />} > </Route>
          <Route path="/reset-password" exact element={<ResetPassword />} > </Route>
          {/* <Route path="/login/:operation" element={<Login />} > </Route> */}

          {/*  */}
          <Route path="/admin/login" exact element={CheckIsAuth() ? <Dashboard /> : <LoginAdmin />} authority='admin.console.read' />

          {/* <Route path="/admin/reset-password" exact element={<Protected><ResetPasswordAdmin /></Protected>} /> */}

          <Route path="/admin/*" exact element={
            <Protected><Dashboard /></Protected>
          } authority='admin.console.read' />

          <Route path="/admin/users/*" exact element={
            <Protected><Dashboard /></Protected>
          } authority='admin.console.read' />

          <Route path="/admin/userProfile/*" exact element={
            <Protected><Dashboard /></Protected>
          } authority='admin.console.read' />

          <Route path="/admin/roles/*" exact element={
            <Protected><Dashboard /></Protected>
          } authority='admin.console.read' />

          <Route path="/admin/profile/*" exact element={
            <Protected><Dashboard /></Protected>
          } authority='admin.console.read' />


          <Route path="*" element={<NotFound />} />

        </Routes>

        <Footer />
      </Router>
      <ToastContainer position={"bottom-right"} />

    </QueryClientProvider>
  )
}

export default App;

