import React, { useState } from "react";
import PlainWebPageService from "../services/PlainWebPageService";
import "../styles/About.css";
import { useEffect } from "react";
// import { getAboutPage } from "../services/api";
import axios from "axios";
import PublicationService from "../services/PublicationService";
import { useQuery } from "react-query";
import { getAboutPage } from "../services/api";
import { getVolunteerWithUs } from "../services/api/user";
import Convert64ToImage from "../services/Convert64ToImage";
import Loader from "../components/Loader";

function VolunteerWithUs() {

  const { isLoading, error, data } = useQuery('pageVolunteerWithUs',
    () => (  getVolunteerWithUs()  )
  )

  const obj = { pageName: "", description: "", mainImage: "" };
  const { pageName, description, mainImage } = data?.data || obj;



  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    )
  }

  return (
    <div className="support">

      <div className="supportBottom">
        <h1 className="pb-5">{pageName}</h1>
        <p className="h5 pb-5 lh-base w-100">{description}</p>
        <Convert64ToImage mainImage={mainImage} />
      </div>
    </div>
  );
}

export default VolunteerWithUs;
