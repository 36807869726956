import axios from "axios";

const api = axios.create({
  baseURL: "https://dehope-belg.com/dehope-backend/api/v1",
 //baseURL: "http://localhost:8080/dehope-backend/api/v1",
});


const axiosRequest = async (method, url, data = null, params = null) => {
  const config = {
    method,
    url,
    headers: {
      // authorization: `Bearer ${localStorage.getItem("token").split('"')[1]}`,
      "Content-Type": "application/json",
    },
    data,
    params,
  };

  try {
    return await api(config);
  } catch (error) {
    return error;
  }
};


export const axiosRequestDocument = async (method, url, data = null, params = null) => {
  const config = {
    method,
    url,
    ResponseType: 'blob',
    setTimeout: 2000,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    },
    data,
    params,
  };

  try {
    return await api(config);
  } catch (error) {
    return error;
  }
};

export default axiosRequest;
