import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const initialState = {
  projectId: 0,
  projectTypeId: 0,
  imgSrc: "",
  donateValue: 0
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    sendProjectData: (state, action) => {
      // state.projectId = action.payload;
      // console.log(action.payload)
      console.log(" ****************************************** ")
    },
    getIdTypeProject: (state, action) => {
      state.projectTypeId = action.payload;
    },
    
    getImgSrc: (state, action) => {
      console.log(action.payload)
      state.imgSrc = action.payload;
    },

    getValueDonate: (state, action) => {
      state.donateValue = action.payload
    }

  },
});

export const { sendProjectData, getIdTypeProject, getImgSrc, getValueDonate } = projectSlice.actions;

export default projectSlice.reducer;
