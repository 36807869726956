import axios from 'axios'
import Cookies from 'js-cookie';
import HelperUtil from './ServiceHelperUtil';

const EMAIL_BASE_REST_API_URL = HelperUtil.getBaseURL() + '/contact';
const currentLanguageCode = Cookies.get('i18next') || 'fr'

class EmailService{

    /**
     * Get all projects
     * @returns 
     */
    contactUsSendEmail(request){
        return axios.post( EMAIL_BASE_REST_API_URL + '/contact-us', request );
    }

    

}

export default new EmailService();