import React from 'react'
import { Link } from 'react-router-dom'

import Causes1 from "../../assets/img/causes/causes-1.png"
import Causes2 from "../../assets/img/causes/causes-2.png"
import Causes3 from "../../assets/img/causes/causes-3.png"
import Causes4 from "../../assets/img/causes/causes-4.png"
import Causes5 from "../../assets/img/causes/causes-5.png"
import Causes6 from "../../assets/img/causes/causes-6.png"
import Cal from "../../assets/img/icon/cal.png"
import UserImg from "../../assets/img/icon/user.png"

const TrendingCausesMain = () => {
  return (
    <>
      <section id="trending_causes_main" className="section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="section_heading">
                <h3>Trending causes</h3>
                <h2> We are always where other people <span className="color_big_heading">need</span> help</h2>
              </div>
            </div>
          </div>
          <div className="row" id="counter">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="case_boxed_wrapper">
                <div className="case_boxed_img">
                  <Link to="/CausesDetails">
                    <img src={Causes1} alt="img" />
                  </Link>
                  {/* <span className="causes_badge bg-theme">Food & water</span> */}
                </div>
                <div className="causes_boxed_text">
                  <div className="class-full causes_pro_bar progress_bar">
                    <div className="class-full-bar-box">
                      <h3 className="h3-title">Goal: <span>$10,000</span></h3>
                      <div className="class-full-bar-percent">
                        <h2><span className="counting-data" data-count="85">0</span>
                          <span>%</span>
                        </h2>
                      </div>
                      <div className="skill-bar class-bar" data-width="85%">
                        <div className="skill-bar-inner class-bar-in"></div>
                      </div>
                    </div>
                  </div>
                  <h3><Link to="/CausesDetails">Collect fund for drinking water & healthy food</Link></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur notted
                    adipisicing elit sed do eiusmod tempor.</p>
                  <div className="causes_boxed_bottom_wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="casuses_bottom_boxed">
                          <div className="casuses_bottom_icon">
                            <img src={Cal} alt="icon" />
                          </div>
                          <div className="casuses_bottom_content">
                            <h5>Date:</h5>
                            <p>20 Dec, 2021</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="casuses_bottom_boxed casuses_left_padding">
                          <div className="casuses_bottom_icon">
                            <img src={UserImg} alt="icon" />
                          </div>
                          <div className="casuses_bottom_content">
                            <h5>By:</h5>
                            <p>Admin</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="case_boxed_wrapper">
                <div className="case_boxed_img">
                  <Link to="/CausesDetails">
                    <img src={Causes2} alt="img" />
                  </Link>
                  <span className="causes_badge bg-yellow">Education</span>
                </div>
                <div className="causes_boxed_text">
                  <div className="class-full causes_pro_bar progress_bar">
                    <div className="class-full-bar-box">
                      <h3 className="h3-title">Goal: <span>$45,000</span></h3>
                      <div className="class-full-bar-percent">
                        <h2><span className="counting-data" data-count="45">0</span>
                          <span>%</span>
                        </h2>
                      </div>
                      <div className="skill-bar class-bar" data-width="45%">
                        <div className="skill-bar-inner class-bar-in"></div>
                      </div>
                    </div>
                  </div>
                  <h3><Link to="/CausesDetails">Give children a good education
                    & better life</Link></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur notted
                    adipisicing elit sed do eiusmod tempor.</p>
                  <div className="causes_boxed_bottom_wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="casuses_bottom_boxed">
                          <div className="casuses_bottom_icon">
                            <img src={Cal} alt="icon" />
                          </div>
                          <div className="casuses_bottom_content">
                            <h5>Date:</h5>
                            <p>20 Dec, 2021</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="casuses_bottom_boxed casuses_left_padding">
                          <div className="casuses_bottom_icon">
                            <img src={UserImg} alt="icon" />
                          </div>
                          <div className="casuses_bottom_content">
                            <h5>By:</h5>
                            <p>Admin</p>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="case_boxed_wrapper">
                <div className="case_boxed_img">
                  <Link to="/CausesDetails">
                    <img src={Causes3} alt="img" />
                  </Link>
                  {/* <span className="causes_badge bg-theme">Home & shelter</span> */}
                </div>
                <div className="causes_boxed_text">
                  <div className="class-full causes_pro_bar progress_bar">
                    <div className="class-full-bar-box">
                      <h3 className="h3-title">Goal: <span>$14,000</span></h3>
                      <div className="class-full-bar-percent">
                        <h2><span className="counting-data" data-count="55">0</span>
                          <span>%</span>
                        </h2>
                      </div>
                      <div className="skill-bar class-bar" data-width="55%">
                        <div className="skill-bar-inner class-bar-in"></div>
                      </div>
                    </div>
                  </div>
                  <h3><Link to="/CausesDetails">Collect fund for drinking water & healthy food</Link></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur notted
                    adipisicing elit sed do eiusmod tempor.</p>
                  <div className="causes_boxed_bottom_wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="casuses_bottom_boxed">
                          <div className="casuses_bottom_icon">
                            <img src={Cal} alt="icon" />
                          </div>
                          <div className="casuses_bottom_content">
                            <h5>Date:</h5>
                            <p>20 Dec, 2021</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="casuses_bottom_boxed casuses_left_padding">
                          <div className="casuses_bottom_icon">
                            <img src={UserImg} alt="icon" />
                          </div>
                          <div className="casuses_bottom_content">
                            <h5>By:</h5>
                            <p>Admin</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="case_boxed_wrapper">
                <div className="case_boxed_img">
                  <Link to="/CausesDetails">
                    <img src={Causes4} alt="img" />
                  </Link>
                  {/* <span className="causes_badge bg-theme">Food & water</span> */}
                </div>
                <div className="causes_boxed_text">
                  <div className="class-full causes_pro_bar progress_bar">
                    <div className="class-full-bar-box">
                      <h3 className="h3-title">Goal: <span>$10,000</span></h3>
                      <div className="class-full-bar-percent">
                        <h2><span className="counting-data" data-count="55">0</span>
                          <span>%</span>
                        </h2>
                      </div>
                      <div className="skill-bar class-bar" data-width="55%">
                        <div className="skill-bar-inner class-bar-in"></div>
                      </div>
                    </div>
                  </div>
                  <h3><Link to="/CausesDetails">Ensure pure and mineral drinking water for rural people</Link>
                  </h3>
                  <p>Lorem ipsum dolor sit amet, consectetur notted
                    adipisicing elit sed do eiusmod tempor.</p>
                  <div className="causes_boxed_bottom_wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="casuses_bottom_boxed">
                          <div className="casuses_bottom_icon">
                            <img src={Cal} alt="icon" />
                          </div>
                          <div className="casuses_bottom_content">
                            <h5>Date:</h5>
                            <p>20 Dec, 2021</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="casuses_bottom_boxed casuses_left_padding">
                          <div className="casuses_bottom_icon">
                            <img src={UserImg} alt="icon" />
                          </div>
                          <div className="casuses_bottom_content">
                            <h5>By:</h5>
                            <p>Admin</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="case_boxed_wrapper">
                <div className="case_boxed_img">
                  <Link to="/CausesDetails">
                    <img src={Causes5} alt="img" />
                  </Link>
                  <span className="causes_badge bg-yellow">Wild animal</span>
                </div>
                <div className="causes_boxed_text">
                  <div className="class-full causes_pro_bar progress_bar">
                    <div className="class-full-bar-box">
                      <h3 className="h3-title">Goal: <span>$16,000</span></h3>
                      <div className="class-full-bar-percent">
                        <h2><span className="counting-data" data-count="45">0</span>
                          <span>%</span>
                        </h2>
                      </div>
                      <div className="skill-bar class-bar" data-width="45%">
                        <div className="skill-bar-inner class-bar-in"></div>
                      </div>
                    </div>
                  </div>
                  <h3><Link to="/CausesDetails">Ensure a secure and free life for the wild animal</Link></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur notted
                    adipisicing elit sed do eiusmod tempor.</p>
                  <div className="causes_boxed_bottom_wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="casuses_bottom_boxed">
                          <div className="casuses_bottom_icon">
                            <img src={Cal} alt="icon" />
                          </div>
                          <div className="casuses_bottom_content">
                            <h5>Date:</h5>
                            <p>20 Dec, 2021</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="casuses_bottom_boxed casuses_left_padding">
                          <div className="casuses_bottom_icon">
                            <img src={UserImg} alt="icon" />
                          </div>
                          <div className="casuses_bottom_content">
                            <h5>By:</h5>
                            <p>Admin</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="case_boxed_wrapper">
                <div className="case_boxed_img">
                  <Link to="/CausesDetails">
                    <img src={Causes6} alt="img" />
                  </Link>
                  {/* <span className="causes_badge bg-theme">Home & shelter</span> */}
                </div>
                <div className="causes_boxed_text">
                  <div className="class-full causes_pro_bar progress_bar">
                    <div className="class-full-bar-box">
                      <h3 className="h3-title">Goal: <span>$11,000</span></h3>
                      <div className="class-full-bar-percent">
                        <h2><span className="counting-data" data-count="89">0</span>
                          <span>%</span>
                        </h2>
                      </div>
                      <div className="skill-bar class-bar" data-width="89%">
                        <div className="skill-bar-inner class-bar-in"></div>
                      </div>
                    </div>
                  </div>
                  <h3><Link to="/CausesDetails">Stop early marriage and educate your girl child</Link></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur notted
                    adipisicing elit sed do eiusmod tempor.</p>
                  <div className="causes_boxed_bottom_wrapper">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="casuses_bottom_boxed">
                          <div className="casuses_bottom_icon">
                            <img src={Cal} alt="icon" />
                          </div>
                          <div className="casuses_bottom_content">
                            <h5>Date:</h5>
                            <p>20 Dec, 2021</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="casuses_bottom_boxed casuses_left_padding">
                          <div className="casuses_bottom_icon">
                            <img src={UserImg} alt="icon" />
                          </div>
                          <div className="casuses_bottom_content">
                            <h5>By:</h5>
                            <p>Admin</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TrendingCausesMain