import React, { useState } from 'react'
import { Buffer } from 'buffer';
import Slider from 'react-slick';
import ImageModal from './ImageModal';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

const Convert64ToImageZoomed = ({ mainImages }) => {

  let allImgs = mainImages


  const [isFullScreen, setIsFullScreen] = useState(false)


  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClick = index => {
    console.log(index)
    setSelectedIndex(index);
  };



  let images = []
  allImgs.map((img) => {

    const base64String = img.image;

    const buffer = Buffer.from(base64String, 'base64');

    const image = new Image();

    image.src = URL.createObjectURL(new Blob([buffer], { type: 'image/*' }));
    images.push(image.src)
  })

  const makeFullScreen = (e) => {
    setIsFullScreen(!isFullScreen);
  }


  return (
    <div className='dad_img row'>
      {/* <img src={image.src} className={isFullScreen ? 'full-screen' : ''} onClick={() => makeFullScreen(image.src)} alt="" id="img-64" /> */}

      {images.map((image, index) => (
        <div className='col-lg-4 col-xl-3 col-md-6 col-12 cursor_pointer_moh' key={index}>
          <img
            src={`${image}`}
            alt="Slider Image"
            onClick={() => handleClick(index)}
          />
        </div>
      ))}
      
      {selectedIndex !== null && (
        <ImageModal
          images={images}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      )}
    </div>
  )
}

export default Convert64ToImageZoomed