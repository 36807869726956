import axios from 'axios'

//deployed backend for testing
// Prod API
//const BASE_URL = 'http://localhost:8080/dehope-backend/api/v1'; 
//const BASE_URL = "http://ahmed-tayeh.com";
//const BASE_URL = 'https://ahmed-tayeh.com/dehope-backend/api/v1'; 
const BASE_URL = 'https://dehope-belg.com/dehope-backend/api/v1'; 

class ServiceHelperUtil{

    getBaseURL(){
        return BASE_URL;
    }

    
}

export default new ServiceHelperUtil();