import React, { useEffect, useState } from "react"
import { apiClient } from "../../../services/auth/ApiClient";
import { notify } from "../../Toast";

const EditUserAssignRole = (props) => {

  const user = props.user;

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    apiClient.getAllRoles()
      .then(allRoles => {
        const sorted = allRoles.sort((r1, r2) => r1.id - r2.id);
        setRoles(sorted);
      });
  }, []);

  const handleSubmit = async (event) => {
    // Prevent default behavior
    event.preventDefault();

    let formData = new FormData(event.target);

    let selectedRoleIds = [];
    formData.forEach((value, key) => selectedRoleIds.push(value));

    const updated = await apiClient.assignRolesToUser(user.id, selectedRoleIds);
    props.updateUser(updated);
    notify({info: "User info updated successfully", status: "success"});

  }

  return (
    <div>
      {user &&
        <div className='car d'>
          <div className='card-header' style={{ border: "1px solid" }}>
            <div className='row'>
              <div className='col-12'>
                <span>Assign role to the user <b>{user.username}</b></span>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-sm-12'>
                <form onSubmit={handleSubmit}>
                  <ul className="list-group">
                    {roles.map(role => (
                      <li className={`list-group-item ${localStorage.getItem("mode") == "dark" ? "text-light" : "main-color-dash"}`} style={{ background: "transparent" }} key={role.id}>
                        <div className="form-check mb-2">
                          <input className="form-check-input" type="checkbox"
                            value={role.id}
                            name={role.id}
                            defaultChecked={user.roles.includes(role.name)}
                            id={role.id} />
                          <h5>{role.name}</h5>
                        </div>
                        <div className="row">
                          {role.authorities.map(authority => (
                            <div
                              className="col-sm-6 col-md-4 col-xl-3 border border-secondary py-2"
                              key={authority.id}>{authority.authority}</div>
                          )
                          )}
                        </div>
                      </li>
                    )
                    )}
                  </ul>
                  <div className='row mt-3'>
                    <button className='btn btn-primary' type='submit'>Assign roles</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default EditUserAssignRole;