import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Convert64ToImage from '../../services/Convert64ToImage';

import Cookies from "js-cookie";
import PlainWebPageService from '../../services/PlainWebPageService';
import AboutImage2 from "../../assets/img/icon/about.png"


const currentLanguageCode = Cookies.get('i18next') || 'fr'


const AboutUsComponent = () => {
    const { t } = useTranslation();
    const [aboutUsPage, setAboutUsPage] = useState('')
    const [shortDescription, setShortDescription] = useState('')
  
  
  useEffect(() => {

    PlainWebPageService.getPlainWebPageDetailsWithSpecificLangAndId(1,  currentLanguageCode).then((response) => {
        setAboutUsPage(response.data)
        // console.log(response.data);
        setAboutUsPage(response.data);
        setShortDescription(response.data?.description.substring(0, 564) || "")
      }).catch(error => {
        console.log(error);
      })
  }, [])

  return (
    <section id="about_area" className="section_padding_bottom">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="about_area_img">
            {/* <img src={AboutImage} alt="img" /> */}
            <Convert64ToImage  mainImage={ aboutUsPage.mainImage} />
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="about_area_main_text">
            <div className="about_area_heading_two">
              <img src={AboutImage2} alt="img" width="70px" />
              <h1 className='color_big_heading' style={{ width: "fit-content" }}>{aboutUsPage.pageName}</h1>
              {/* <span className="color_big_heading">poverty</span> <br /> */}
            </div>
            <div className="about_area_para">
              {/* <p>{shortDescription}.</p> */}
              <p>{t('home_about_us_short_description')}</p>
            </div>
            <div className="about_vedio_area">
              <Link to="/whoWeAre" className="btn btn_theme btn_md">{t("link_now_more_about_US")}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default AboutUsComponent
