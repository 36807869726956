import { useQuery } from "react-query";
import React, { useState, useEffect } from "react";
import FileDownloaderHelperUtil from "../helpers/FileDownloaderHelperUtil";
import PlainWebPageService from "../services/PlainWebPageService";
import "../styles/About.css";
import { getAboutPage } from "../services/api";
import { getSupportPage, getTypeProjects } from "../services/api/user";
import "../styles/loader.css";
import Convert64ToImage from "../services/Convert64ToImage";
import Loader from "../components/Loader";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import CheckoutForm from "../components/payment/CheckoutForm";
import Payment from "../components/payment/payment";
import { notify } from "../components/Toast";
import CharityProjectTypeService from "../services/CharityProjectTypeService";

const SupportUs = () => {

  // const { isLoading, error, data } = useQuery('pageSupport',
  //   () => (getSupportPage())
  // )



  const { t } = useTranslation()


  const [showModal, setShowModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  // Check where to move these
  // const [donationValue, setDonationValue] = useState();
  const [dataPage, setDataPage] = useState()
  const [donateValue, setDonateValue] = useState()
  const [charityProjectTypeId, setCharityProjectTypeId] = useState();
  const [charityProjectTypeName, setCharityProjectTypeName] = useState();
  const [descriptionDonate, setDescriptionDonate] = useState();
  const [projectsTypes, setProjectsTypes] = useState()
  const [projectTypeId, setProjectTypeId] = useState()

  const [isLoading, setIsLoading] = useState(true)
  const showModalFunction = () => {
    if (!donateValue || donateValue <= 0 ||  !projectTypeId || !charityProjectTypeName) { // && !charityProjectTypeName
      setErrorMessage(t("volunteer_with_us_donation_alert_you_should_put_value"))
      notify({ info: t("volunteer_with_us_donation_alert_you_should_put_value"), status: "failed" })
    }
    else {
      setErrorMessage("")
      setShowModal(true)
    }
  }

  useEffect(() => {
    PlainWebPageService.getPlainWebPageDetailsWithSpecificLangAndId(2)
      .then((response) => {
        setDataPage(response.data)
        setIsLoading(false)
        // console.log(response.data)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      }
      )
  }, [])


  const obj = { pageName: "", description: "", mainImage: "" };
  const { pageName, description, mainImage } = dataPage || obj;

  useEffect(() => {
    CharityProjectTypeService.getProjectTypesWithSpecificLang()
      .then((response) => {
        setProjectsTypes(response.data)
      }).catch(error => {
        console.log(error);
      })
  }, [])

  const handleChange = (event) => {
    setCharityProjectTypeId(event.target.value);
  }

  useEffect(() => {
    if (charityProjectTypeId) {
      setProjectTypeId(charityProjectTypeId.split("-")[0])
      setCharityProjectTypeName(charityProjectTypeId.split("-")[1])
    }
  }, [charityProjectTypeId, projectTypeId]);
  // 

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    )
  }

  return (
    <div className="support">
      <div className="supportBottom">
        <div className="support_side">
          <h1 className="pb-5 text-center">{pageName}</h1>
          {/* <p className="h5 pb-5 lh-base" style={{ wordWrap: 'break-word' }}>{description} */}
          <div dangerouslySetInnerHTML={{ __html: description }} />

          {/* </p> */}
        </div>

        <div className="donate-btn">
          <label className="px-1" >{t("volunteer_with_us_donation_value_label")}</label>
          <input type="number" placeholder={t('volunteer_with_us_donation_eur')} value={donateValue} onChange={(e) => setDonateValue(e.target.value)} />

          <label className="px-1" >{t("volunteer_with_us_donation_eur_label")}</label>
          <select className="form-select dropdown_support" aria-label=".form-select-lg example"
            onChange={handleChange} value={charityProjectTypeId}>
            <option  >
              {t('volunteer_with_us_donation_choose_project')}
            </option>
            {
              projectsTypes && projectsTypes.map(el =>
                <option value={el.id + "-" + el.content[0].projectType} key={el.content[0].id}>
                  {el.content[0].projectType}
                </option>
              )}
          </select>

          <label className="px-1" >{t("volunteer_with_us_donation_description_label")}</label>
          <input type="text" placeholder={t('volunteer_with_us_donation_description')} value={descriptionDonate} onChange={(e) => setDescriptionDonate(e.target.value)} />

          <button className="btn btn_md btn_theme" onClick={() => showModalFunction()}>
            {t("volunteer_with_us_donation_value")}
          </button>
        </div>

      </div>
      <div className="text-error">{errorMessage}</div>

      <Payment
        donateValue={donateValue} showModal={showModal}
        setShowModal={setShowModal} setDonateValue={setDonateValue}
        charityProjectTypeName={charityProjectTypeName}
        descriptionDonate={descriptionDonate}
        charityProjectTypeId={projectTypeId}
      />

      <Convert64ToImage mainImage={mainImage} />

    </div>
  )
}
export default SupportUs