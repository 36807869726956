import React, {useEffect, useState} from "react"
import { apiClient } from "../../services/auth/ApiClient";
import { authService } from "../../services/auth/AuthService";

import RolesViewAll from "../../components/dashboard/roles/RolesViewAll"
import RolesCreateNew from "../../components/dashboard/roles/RoleCreateNew";

const Roles = ({ isSidebarOpen }) => {

    const [roles, setRoles] = useState([]);
    const [lastModifiedRole, setLastModifiedRole] = useState({});

    useEffect(() => {
        apiClient.getAllRoles()
            .then(allRoles => {
                const sorted = allRoles.sort((r1, r2) => r1.id - r2.id);
                setRoles(sorted);
            });

            
    }, [lastModifiedRole]);

  // width: 80%;
  // margin: 20px 40px auto auto !important;

    return (
        <div className={`userPage ${isSidebarOpen ? " userPage-open " : " userPage-close mx-3 mx-lg-5"}`}>
            {authService.userHasAuthority('role.read') &&
                <RolesViewAll roles={roles} setLastModifiedRole={setLastModifiedRole}/>
            }
            {authService.userHasAuthority('role.create') &&
                <RolesCreateNew setLastModifiedRole={setLastModifiedRole}/>
            }
        </div>
    )
}

export default Roles;