import axios from 'axios'
import HelperUtil from './ServiceHelperUtil';
import Cookies from "js-cookie"

const currentLanguageCode = Cookies.get('i18next') || 'fr'

const PROJECT_TYPE_BASE_REST_API_URL = HelperUtil.getBaseURL() + '/charityProjects/types';

class CharityProjectTypeService{

   /**
    * Get all project types in all lan (active and inactive)
    * @returns 
    */
    getALLProjectTypes(){
        return axios.get( PROJECT_TYPE_BASE_REST_API_URL );
    }

    /**
     * Get all project types with specific lang
     * @param {*} lang 
     * @returns 
     */
    getProjectTypesWithSpecificLang(){
        return axios.get(PROJECT_TYPE_BASE_REST_API_URL + '/' + currentLanguageCode);
    }

    /**
     * Get project type by id
     * @param {*} id 
     * @returns 
     */
    getProjectTypeById(id){
        return axios.get(PROJECT_TYPE_BASE_REST_API_URL + '/type/' + id);
    }

    /**
     * Get project type by id and lang
     * @param {*} id 
     * @param {*} lang 
     * @returns 
     */
    getProjectTypeByIdWithSpecificLang(id){
        return axios.get(PROJECT_TYPE_BASE_REST_API_URL + '/type/' + id + '/' + currentLanguageCode);
    }

}

export default new CharityProjectTypeService();