import React, { useEffect, useState } from 'react';
import Logo from "../assets/img/logo.png"
import { AiOutlineCloseCircle } from "react-icons/ai"


function ImageModal({ images, selectedIndex, setSelectedIndex }) {
  // console.log(images)

  const [makeDisablePrev, setMakeDisablePrev] = useState(false)
  const [makeDisableNext, setMakeDisableNext] = useState(false)
  const [transition, setTransition] = useState("");

  const handleClose = () => {
    setSelectedIndex(null);
  };

  const handleNext = () => {
    setTransition("translateX(-100%)");
    setTimeout(() => {
      setSelectedIndex((selectedIndex + 1) % images.length);
      setTransition("translateX(0)");
    }, 300);
  };

  const handlePrev = () => {
    setTransition("translateX(100%)");
    setTimeout(() => {
      setSelectedIndex((selectedIndex - 1 + images.length) % images.length);
      setTransition("translateX(0)");
    }, 300);
  };


  useEffect(() => {
    if (images.length === selectedIndex + 1) setMakeDisableNext(true)
    else setMakeDisableNext(false)

    if (selectedIndex === 0) setMakeDisablePrev(true)
    else setMakeDisablePrev(false)
  }, [selectedIndex,])

  return (
    <div className="modal-overlay-fully position-relative">
      <div className={`${selectedIndex >= 0 ? "modal" : ""}`}>
        <img src={`${images[selectedIndex]}`} alt="Modal Image"
          className="modal-content-move" style={{ transform: transition }} />

        <div className='btns_Modal'>
          <button onClick={handlePrev} disabled={makeDisablePrev}
            style={{ opacity: makeDisablePrev && .7 }}
          >Prev</button>
          <button onClick={handleNext} disabled={makeDisableNext} style={{ opacity: makeDisableNext && .7 }}>Next</button>
        </div>

        {/* <button onClick={handleClose} className="closeModal"> */}
        <AiOutlineCloseCircle onClick={handleClose} className='closeModal h2 mb-0' />
        {/* </button> */}

      </div>
    </div>
  );
}

export default ImageModal