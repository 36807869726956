import React, { useEffect, useState } from "react"
import { apiClient } from "../../../services/auth/ApiClient";

const RolesCreateNew = (props) => {

  const [authorities, setAuthorities] = useState([]);
  const [roleAuthorityIds, setRoleAuthorityIds] = useState([]);
  const [roleName, setRoleName] = useState([]);

  useEffect(() => {
    apiClient.getAllAuthorities()
      .then(allAuthorities => {
        const sorted = allAuthorities.sort((a1, a2) => a1.authority.localeCompare(a2.authority));
        setAuthorities(sorted);
      });
  }, []);

  const handleSubmit = async (event) => {
    // Prevent default behavior
    event.preventDefault();

    const created = await apiClient.createRole(roleName, roleAuthorityIds);
    props.setLastModifiedRole(created);

  }

  const handleAuthorityCheckChange = async (event) => {

    const checked = event.target.checked;
    const id = +event.target.value;

    let ids = roleAuthorityIds.filter(i => i !== id);
    if (checked) ids.push(id);

    setRoleAuthorityIds(ids);
  }

  const handleRoleNameChange = async (event) => {
    const name = event.target.value;
    setRoleName(name);
  }

  return (
    <div>
      <div className='card' style={{ background: "transparent" }}>
        <div className='card-header'>
          <div className='row'>
            <div className='col-12'>
              <span>Create new Role</span>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className="mb-3">
                <label htmlFor="roleName" className="form-label">Role name</label>
                <input type="text" className="form-control bg-" id="roleName" placeholder='USER' onChange={handleRoleNameChange} />
                <div id="roleNameHelp" className="form-text">
                  Role name can contain only alphanumeric symbols.
                </div>
              </div>
            </div>
            <div className='row mb-4'>
              
              {authorities.map(authority => (
                <div className="col-sm-6 col-md-4 col-xl-3 border border-secondary py-2 d-flex align-items-center" style={{ margin: "7px 0", padding: "10px 10px" }}
                  key={authority.id}>
                  <input className="form-check-input" type="checkbox"
                    value={authority.id}
                    name={authority.id}
                    checked={roleAuthorityIds?.includes(authority.id)}
                    onChange={handleAuthorityCheckChange}
                    id={`authority-check-${authority.id}`} />
                  <label className={`form-check-label my-0 px-2  ${localStorage.getItem("mode") == "dark" ? "text-light" : "main-color-dash"}`}
                    htmlFor={`authority-check-${authority.id}`}>
                    {authority.authority}
                  </label>
                </div>
              )
              )}
            </div>
            <div className='row'>
              <button className='btn btn-primary' type='submit'
                disabled={!roleName}>
                Create role
              </button>
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default RolesCreateNew;