import axios from 'axios'
import { t } from 'i18next';
import Moment, {format} from 'moment';



class HelperUtil{

    formatDate = (date) => {
        return (Moment(date).format('MMMM Do YYYY, h:mm:ss a'));
    }

     getCurrencyNameLocale = (currency) => {
        if(currency === "EUR"){
            return t('currency_eur')
        }
        if(currency === "USD"){
            return t('currency_usd')

        }
        if(currency === "ILS"){
            return t('currency_ils')

        }
        if(currency === "JOD"){
            return t('currency_jod')

        }
        if(currency === "EGP"){
            return t('currency_egp')

        }
        if(currency === "TRY"){
            return t('currency_try')

        }
        if(currency === "AED"){
            return t('currency_aed')

        }
    }

     getUserRoleTypeNameLang = (roleType) =>{
    
        if(roleType === 'ADMIN'){
                return t('user_admin_role_name')
        }
        else if (roleType ==='MEDIATOR'){
            return t('user_mediator_role_name')

        }
        else if (roleType==="BROKER"){
            return t('user_broker_role_name')

        }
        else if (roleType ==="CLIENT"){
            return t('user_client_role_name')

        }
    }

    getFeeTypeNameLang = (feeType) => {
        if(feeType === 'PERCENTAGE'){
            return t('transfer_fee_type_percentage')
        }
        else if (feeType === 'VALUE'){
            return t('transfer_fee_type_value')
        }
    }

    
}

export default new HelperUtil();