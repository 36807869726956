import React, { useState, useEffect } from 'react'
import { apiClient } from '../../../services/auth/ApiClient';
import Modal from '../../Modal';
import UsersInfo from './UsersInfo';

import 'react-toastify/dist/ReactToastify.css';

const TableUsersB = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAuthorities, setShowAuthorities] = useState(false);

  useEffect(() => {
    apiClient.getAllUsers()
      .then(allUsers => {
        setIsLoading(false)
        setUsers(allUsers)
      });
  }, []);


  // console.log(users)

  if (isLoading) {
    return (
      <div className='h4 mx-auto py-2'>
        Loading....
      </div>
    )
  }

  return (
    <>

      <div className="d-flex justify-content-between p-2 border">
        <div className="p-2 w-25">Name</div>
        <div className="p-2 w-25">Position</div>
        <div className="p-2 w-25">Title</div>
        <div className="p-2 w-25">Actions</div>
      </div>

      {/* <Modal /> */}




      {
        users?.map(user =>
          <UsersInfo key={user.id} user={user} showAuthorities={showAuthorities} setShowAuthorities={setShowAuthorities} />
        )
      }

    </>
  )
}

export default TableUsersB