import React, { useState } from "react";
import PlainWebPageService from "../services/PlainWebPageService";
import "../styles/About.css";
import { useEffect } from "react";
// import { getAboutPage } from "../services/api";
import axios from "axios";
import PublicationService from "../services/PublicationService";
import { useQuery } from "react-query";
import { getAboutPage } from "../services/api";
import Convert64ToImage from "../services/Convert64ToImage";
import Loader from "../components/Loader";

function About() {


  // const { isLoading, error, data } = useQuery('pageAbout',
  //   () => (  getAboutPage()  )
  // )


  const [dataPage, setDataPage] = useState()
  const [isLoading, setIsLoading] = useState(false)

useEffect(() => {
    setIsLoading(true)
    PlainWebPageService.getPlainWebPageDetailsWithSpecificLangAndId(1)
      .then((response) => {
        setDataPage(response.data)
        setIsLoading(false)
        // console.log(response.data)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      }
      )
  }, [])


  const obj = { pageName: "", description: "", mainImage: "" };
  const { pageName, description, mainImage } = dataPage || obj;

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    )
  }

  return (
    <div className="aboutUs">
      <div className="aboutSection">
        <h1 className="pb-5 text-center">{pageName}</h1>
        <div dangerouslySetInnerHTML={{ __html: description }} />
        {/* <p className="h5 pb-5 lh-base w-100">{description}</p> */}
        <Convert64ToImage mainImage={mainImage} />
      </div>
    </div>
  )
}

export default About;
