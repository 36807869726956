import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useLocation, useParams } from 'react-router-dom'
import Gallery1 from "../assets/img/gallery/gallery-grid-1.png"
import Gallery2 from "../assets/img/gallery/gallery-grid-2.png"
import Gallery3 from "../assets/img/gallery/gallery-grid-3.png"
import Gallery4 from "../assets/img/gallery/gallery-grid-4.png"
import Gallery5 from "../assets/img/gallery/gallery-grid-5.png"
import Gallery6 from "../assets/img/gallery/gallery-grid-6.png"
import ArrowRound from "../assets/img/icon/arrow-round.png"
import { getProjectsWithSameType } from '../services/api/user'
import Loader from './Loader'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import ImageZoom from './ImageZoom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Convert64ToImageZoomed from '../services/Convert64ToImageZoomed'
import { useTranslation } from 'react-i18next'
import CharityProjectServiceImages from '../services/CharityProjectServiceImages'
import CharityProjectService from "../services/CharityProjectService"

const GalleryGrid = ({ typeProject }) => {

  const { t } = useTranslation();

  const { id } = useParams();
  const [selectedId, setSelectedId] = useState(id);


  const refresh = () => window.location.reload(true)

  const handleChange = (event) => {
    setSelectedId(event.target.value);
    window.history.pushState(null, null, `/gallery/${event.target.value}`);
    refresh()
  }

  const [projectsTypes, setProjectsTypes] = useState([])
  const [images, setImages] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    CharityProjectService
      .getProjectByIdWithSpecificLang(id)
      .then((response) => {
        setProjectsTypes(response.data)
        setIsLoading(false)
        // console.log(response.data);
      }).catch(error => {
        console.log(error);
      })

    CharityProjectServiceImages.getGallaryByProjectType(id)
      .then((response) => {
        setImages(response.data)
        //  setIsLoading(false)
        // console.log(response.data);
      }).catch(error => {
        console.log(error);
      })
  }, [])


  if (isLoading) {
    return (
      <><Loader /></>
    )
  }

  return (
    <section id="gallery_grid_area" className="section_padding gallery">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-between mx-auto " style={{ width: "96%" }}>
          <div className="col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="section_heading">
              <h2>{t("gallery_page_header2")}</h2>
            </div>
          </div>
          <select className="form-select form-select-lg col-lg-3 col-md-12 col-sm-12 col-12" aria-label=".form-select-lg example"
            onChange={handleChange} value={selectedId}>
            {typeProject && typeProject.map(el =>
              <option value={el.id} key={el.content[0].id}>
                {el.content[0].projectType}
              </option>
            )}
          </select>
        </div>
        <div className="row popup-gallery py-5">

          <Convert64ToImageZoomed mainImages={images} />

          {
            projectsTypes && projectsTypes?.length == 0 ?
              <h2 className='text-center pt-5 alert '>{t("gallery_page_no_projects_exsit")}</h2>
              : ""
          }


        </div>
      </div>
    </section>
  )
}

export default GalleryGrid