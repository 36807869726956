import React, { useEffect, useState } from 'react'
import Loader from "../components/Loader"
import FileDownloaderHelperUtil from '../helpers/FileDownloaderHelperUtil'
import { getPdf, getPublicationPage } from '../services/api/user'
import Convert64ToImage from "../services/Convert64ToImage"
import { HiOutlineDocumentDownload } from "react-icons/hi"
import { useTranslation } from 'react-i18next'
import PublicationService from '../services/PublicationService'
import { notify } from '../components/Toast'


const Publication = () => {
  const { t } = useTranslation();

  const [dataPage, setDataPage] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  useEffect(() => {
    PublicationService.getActivePublicationsWithSpecificLang()
      .then((response) => {
        setDataPage(response.data)
        setIsLoading(false)
        // console.log(response.data)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      }
      )
  }, [])



  const downloadPDF = async (e) => {
    if (!isButtonDisabled) {
      setSelectedItemIndex(e)

      let res = await getPdf(e);
      // let res
      FileDownloaderHelperUtil.mohammedFunction(res.data)

      setTimeout(() => {
        notify({ info: "You Download Pdf successfully", status: "success" });
        setSelectedItemIndex(null);
      }, 800);

      // PublicationService.getPublicationByIdForDownload(e)
      //   .then((res) => {
      //     console.log(res.data)
      //     FileDownloaderHelperUtil.mohammedFunction(res.data)
      //   }
      //   )
      //   .catch((err) => console.log(err))

    }
  }


  if (isLoading) {
    return (
      <><Loader /></>
    )
  }

  return (
    <div>
      <section className="section_after section_padding bg-color">
        <div className="container">
          <div className="row">
            <div className="section_heading pb-5">
              <h2>{t("our_publications_header")}</h2>
            </div>
          </div>
          <div className="row">
            {
              dataPage && dataPage.length > 0 ? dataPage.map((el, index) =>
                <div className="col-lg-3 col-md-12 col-sm-12 col-12 mb-5" key={el.publicationId}>
                  <div className="case_boxed_wrapper">
                    <div className="case_boxed_img cursor_pointer_moh" onClick={() => downloadPDF(el.publicationId)} >
                      <Convert64ToImage mainImage={el.image} />
                    </div>
                    <div className="causes_boxed_text">
                      <h3 className='main-color cursor_pointer_moh' onClick={() => downloadPDF(el.publicationId)}>
                        <span className='ms-4'>{el.name} </span>
                        {
                          selectedItemIndex === el.publicationId
                            ?
                            <span class="loading-span"></span>
                            :
                            <HiOutlineDocumentDownload className='h3 mx-2' />
                        }
                      </h3>
                      <p>{el.description}</p>
                    </div>
                  </div>
                </div>
              )
                :
                <h4 className='text-center' style={{ padding: "100px 0 150px" }}>There Is No Publications</h4>
            }

          </div>
        </div>
      </section>

    </div>
  )
}

export default Publication