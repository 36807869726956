import React, { useEffect } from 'react'
import ArrowUp from "../assets/img/up-arrow.png"

const BtnScrollUp = () => {
  // button scroll up

  useEffect(() => {
    function scrollUp() {
      let scroll = document.querySelector('.scrollTop')
      scroll.classList.toggle("active", window.scrollY > 500)
    }
    window.addEventListener("scroll", scrollUp)
  }, [])

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  return (
    <div className="scrollTop" onClick={() => scrollToTop()}>
      <img src={ArrowUp} alt="img" className="w- icon-btnUp " />
    </div>
  )
}

export default BtnScrollUp