import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import StripeService from "../../services/StripeService"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";

function Payment({ donateValue, showModal, setShowModal, charityProjectTypeId, charityProjectTypeName, descriptionDonate }) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const { t } = useTranslation()


  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY));
  }, []);

  useEffect(() => {
    // setDonationValue(500);
    // setCharityProjectTypeId(2);
    // setCharityProjectTypeName("TEST TYPE NAME");
    // setDescription("Test Description");
    const donationRequest = { donationValue: donateValue, charityProjectTypeId, charityProjectTypeName, description: descriptionDonate }

    if (showModal) {
      StripeService.createPaymentIntentWithProjectTypeDetails(donationRequest)
        .then((response) => {
          setClientSecret(response.data)
          // console.log(response.data)
        }).catch(error => {
          console.log(error);
        })
    }
  }, [showModal]);

  const handleClose = () => {
    setShowModal(false)
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header>
          <Modal.Title>{t("volunteer_with_us_donation_payment_information")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h3>React Stripe and the Payment Element</h3> */}
          {clientSecret && stripePromise && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm showModal={showModal} setShowModal={setShowModal}
                donateValue={donateValue} />
            </Elements>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Payment;