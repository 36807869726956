import { useEffect } from "react";
import { useState } from "react";
import { authService } from "../../services/auth/AuthService";

export const CheckIsAuth = () => {

  const [loggedInUser, setLoggedInUser] = useState(authService.currentUserValue);


  useEffect(() => {
    authService.currentUser.subscribe(x => setLoggedInUser(x));
  }, []);

  const isAuthenticated = !!loggedInUser;

  return isAuthenticated
}