import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Convert64ToImage from '../../services/Convert64ToImage';

import Cookies from "js-cookie";
import PlainWebPageService from '../../services/PlainWebPageService';
import AboutImage2 from "../../assets/img/icon/about.png"





const DonationMotivationComponent = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  useEffect(() => {

  }, [])

  return (
    <section id="donate_area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="donate_text">
              <h2>
                {t("donate_area_header2")}
              </h2>

              {/* <h3 style={{ color: "#3e5294", fontSize: "27px", margin: "20px 0 30px" }}></h3> */}

              <Link to="/supportUs" className="btn btn_md btn_theme btn_md_orange"
                onClick={() => scrollToTop()} >{t("donate_area_donate_now")}</Link>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default DonationMotivationComponent
